import { useContext, useEffect } from "react";
import useFirestore from "../basic/useFirestore";
import { collection, getDocs, query, where } from "firebase/firestore";
import { FirebaseContext } from "../../providers/FirebaseProvider/FirebaseProvider";

function useUserGroups(managerId) {
  const { firestoreDb } = useContext(FirebaseContext);

  const firestoreQuery = async () =>
    getDocs(
      query(
        collection(firestoreDb, "groups"),
        where("managerId", "==", managerId),
      ),
    );

  const parsingFuntion = (data) => {
    return data.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
      };
    });
  };

  const { data, error, isLoading, refreshData } = useFirestore(
    firestoreQuery,
    parsingFuntion,
  );

  console.log(data);
  useEffect(() => {
    refreshData();
  }, [managerId]);

  return {
    data,
    error,
    isLoading,
    refreshData,
  };
}

export default useUserGroups;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import { IonChip } from "@ionic/react";

function convertMinutesToHoursAndMinutes(minutes) {
  const hours = Math.floor(minutes / 60);
  const minutesLeft = minutes % 60;
  return `${hours}:${minutesLeft === 0 ? "00" : minutesLeft}`;
}

function PeriodLengthSelector({
  minPeriondInMinutes = 60,
  singleSlotInMinutes = 30,
  totalSlots = 4,
  onChangePeriodLength = () => { },
}) {
  const [periodSelectorModelInMinutes, setPeriodSelectorModelInMinutes] =
    useState([]);
  const [normalizedSelectedDay, setSelectedPeriodLength] = useState();

  const onChangeCallback = (period) => {
    setSelectedPeriodLength(period);
    onChangePeriodLength(period.minutes);
  };

  useEffect(() => {
    const periodSelectorModelInMinutesResult = [];
    let initialPeriod = minPeriondInMinutes;
    const firstIndex = minPeriondInMinutes / singleSlotInMinutes;
    periodSelectorModelInMinutesResult.push({
      minutes: initialPeriod,
      label: convertMinutesToHoursAndMinutes(initialPeriod),
    });
    for (let i = firstIndex; i < totalSlots; i++) {
      initialPeriod += singleSlotInMinutes;
      periodSelectorModelInMinutesResult.push({
        minutes: initialPeriod,
        label: convertMinutesToHoursAndMinutes(initialPeriod),
      });
    }
    console.log(
      "periodSelectorModelInMinutesResult",
      periodSelectorModelInMinutesResult,
    );
    setPeriodSelectorModelInMinutes(periodSelectorModelInMinutesResult);
  }, [minPeriondInMinutes, singleSlotInMinutes, totalSlots]);

  return (
    <Swiper
      spaceBetween={2}
      slidesPerView={"auto"}
      onSlideChange={() => console.log("slide change")}
      onSwiper={(swiper) => console.log(swiper)}
    >
      {periodSelectorModelInMinutes.map((element) => (
        <SwiperSlide key={element.minutes}>
          <IonChip
            color={normalizedSelectedDay === element ? "success" : "primary"}
            onClick={() => onChangeCallback(element)}
          >
            <div>{element.label}</div>
          </IonChip>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

PeriodLengthSelector.propTypes = {
  minPeriondInMinutes: PropTypes.number,
  singleSlotInMinutes: PropTypes.number,
  totalSlots: PropTypes.number,
  onChangePeriodLength: PropTypes.func,
};

export default PeriodLengthSelector;

import React, { useContext, useEffect, useState } from "react";
import { IonButton, IonContent, IonModal, useIonToast } from "@ionic/react";
import "./styles/NotificationCheckModal.scss";
import { NotificationContext } from "../../providers/NotificationsProvider/NotificationProvider";
import {
  getNotificationLocalStorage,
  hasNotificationBlocked,
  hasNotificationFunctionality,
  hasNotificationPermission,
  isInstalled,
  isIOs,
  setNotificationLocalStorage,
} from "../../utils/device";

function NotificationCheckModal() {
  const [show, setShow] = useState(false);
  const { registerNotification } = useContext(NotificationContext);
  const [present] = useIonToast();
  useEffect(() => {
    const isiOs = isIOs();

    if (
      hasNotificationFunctionality() &&
      ((isiOs && isInstalled()) || !isiOs)
    ) {
      // if is the first time the user opens the app
      const hasAccepted = getNotificationLocalStorage();
      if (
        (hasAccepted === null && !hasNotificationBlocked()) ||
        (hasAccepted !== "false" &&
          !hasNotificationPermission() &&
          !hasNotificationBlocked())
      ) {
        setShow(true);
      }
    }
  }, []);

  return (
    <IonModal
      className="NotificationCheckModal"
      isOpen={show}
      onDidDismiss={() => setShow(false)}
    >
      <IonContent>
        <div
          className="ion-padding"
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <h2
            style={{
              marginTop: 0,
              fontWeight: "bold",
            }}
          >
            Notifiche
          </h2>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "15px",
              gap: "1rem",
              fontSize: "1.2rem",
            }}
          >
            <img src="whistle.png" alt="logo" style={{ maxWidth: "50px" }} />
            Vuoi attivare le notifiche per ricevere aggiornamenti?
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "auto",
              fontSize: "1.2rem",
            }}
          >
            <IonButton
              fill="clear"
              size="default"
              style={{
                fontSize: "1rem",
              }}
              onClick={() => {
                setNotificationLocalStorage(false);
                setShow(false);
              }}
            >
              No
            </IonButton>
            <IonButton
              fill="clear"
              style={{
                fontSize: "1rem",
              }}
              onClick={async () => {
                const result = await registerNotification();
                if (result) {
                  present({
                    message: "Notifiche attivate",
                    color: "success",
                    duration: 3000,
                  });
                  setNotificationLocalStorage(true);
                } else {
                  present({
                    message: "Errore attivazione notifiche",
                    color: "danger",
                    duration: 3000,
                  });
                }
                setShow(false);
              }}
            >
              Si
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
}

NotificationCheckModal.propTypes = {};

export default NotificationCheckModal;

import { useContext } from "react";
import { addDoc, collection } from "firebase/firestore";
import { FirebaseContext } from "../../providers/FirebaseProvider/FirebaseProvider";

function useUserFakeCreation() {
  const { firestoreDb } = useContext(FirebaseContext);
  const createFakeUser = async (name, surname) => {
    const userData = {
      nome: name,
      cognome: surname,
    };
    const userTableRef = collection(firestoreDb, "users");
    const document = await addDoc(userTableRef, userData);
    const result = {
      id: document.id,
      ...userData,
    };
    return result;
  };

  return createFakeUser;
}

export default useUserFakeCreation;

import { useContext, useEffect } from "react";
import useFirestore from "../basic/useFirestore";
import { doc, getDoc } from "firebase/firestore";
import { FirebaseContext } from "../../providers/FirebaseProvider/FirebaseProvider";

function useUserSecureAppData(userId) {
  const { firestoreDb } = useContext(FirebaseContext);

  const firestoreQueryMethod = async () =>
    getDoc(doc(firestoreDb, "users_secure", userId));

  const parsingFuntion = (data) => {
    console.log("Parsing data:", data);
    return {
      id: data.id,
      ...data.data(),
    };
  };

  const { data, error, isLoading, refreshData } = useFirestore(
    firestoreQueryMethod,
    parsingFuntion,
    !userId,
  );

  useEffect(() => {
    if (userId) {
      refreshData();
    }
  }, [userId]);

  return {
    data,
    error,
    isLoading,
    refreshData,
  };
}

export default useUserSecureAppData;

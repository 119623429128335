import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import { IonChip } from "@ionic/react";

function TimeSelector({
  bookablePeriods = [],
  bookedSummary = {},
  selectedTime = null,
  onChangeTime = () => { },
  minBookableSlots = 2,
}) {
  const [selectedHour, setSelectedHour] = useState(selectedTime);
  const [timeSelectorModel, setTimeSelectorModel] = useState([]);

  const onChangeCallback = (hour) => {
    if (bookedSummary[hour.hour] || !hour.canBook) {
      return;
    }

    // get the number of contiguous booked hours from the selected hour to first booked hour found
    const startHourIndex = bookablePeriods.indexOf(hour.hour);
    let contiguousBookableSlots = 0;
    let bookedHourFound = false;
    for (
      let i = startHourIndex;
      i < bookablePeriods.length && !bookedHourFound;
      i++
    ) {
      if (bookedSummary[bookablePeriods[i]]) {
        bookedHourFound = true;
        break;
      }
      contiguousBookableSlots++;
    }

    console.log("aaaaa", contiguousBookableSlots);

    setSelectedHour(hour);
    onChangeTime(hour.hour, contiguousBookableSlots);
  };

  useEffect(() => {
    const timeSelectorModelResult = [];
    bookablePeriods.forEach((hour) => {
      const isBooked = bookedSummary[hour] ? true : false;

      // the user can book only if the hour is not booked and the next minBookableSlots hours are not booked
      let canBook = false;

      const startHourIndex = bookablePeriods.indexOf(hour);
      const endHourIndex = startHourIndex + minBookableSlots;

      if (endHourIndex <= bookablePeriods.length) {
        canBook = bookablePeriods
          .slice(startHourIndex, endHourIndex)
          .every((h) => !bookedSummary[h]);
      } else {
        canBook = false;
      }

      let elementColor = selectedHour?.hour === hour ? "success" : "primary";
      elementColor = isBooked ? "danger" : elementColor;
      elementColor = canBook === false && !isBooked ? "warning" : elementColor;

      timeSelectorModelResult.push({
        hour,
        isBooked,
        canBook,
        elementColor,
      });
    });
    setTimeSelectorModel(timeSelectorModelResult);
  }, [bookablePeriods, bookedSummary, selectedHour]);

  return (
    <Swiper
      spaceBetween={2}
      slidesPerView={"auto"}
      onSlideChange={() => console.log("slide change")}
      onSwiper={(swiper) => console.log(swiper)}
    >
      {timeSelectorModel.map((element) => (
        <SwiperSlide key={element.hour}>
          <IonChip
            color={element.elementColor}
            onClick={() => onChangeCallback(element)}
          >
            <div>{element.hour}</div>
          </IonChip>
        </SwiperSlide>
      ))}
      {timeSelectorModel.length === 0 && (
        <SwiperSlide>
          <IonChip color="danger">
            <div>Non ci sono orari disponibili</div>
          </IonChip>
        </SwiperSlide>
      )}
    </Swiper>
  );
}

TimeSelector.propTypes = {
  bookablePeriods: PropTypes.array,
  bookedSummary: PropTypes.object,
  minBookableSlots: PropTypes.number,
  onChangeTime: PropTypes.func,
  selectedTime: PropTypes.string,
};

export default TimeSelector;

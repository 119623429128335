import { IonButton, IonCard, IonContent, IonFooter, IonPage, IonTitle, useIonViewWillEnter } from "@ionic/react";
import React, { useContext } from "react";
import { AuthenticationContext } from "../../../providers/AuthenticationProvider/AuthenticationProvider";
import useUserGroups from "../../../hooks/groups/useUserGroups";
import Spinner from "../../../components/Spinner/Spinner";
import GroupCard from "../../../components/GroupCard/GroupCard";

const GroupManager = () => {
  const { appUserId } = useContext(AuthenticationContext);
  const { data, error, isLoading, refreshData } = useUserGroups(appUserId);

  useIonViewWillEnter(() => {
    refreshData();
  });

  return (
    <IonPage>
      <IonContent>
        {isLoading && <Spinner />}
        {error && <p>Error: {error.message}</p>}
        {data && data.length > 0 && (
          <div>
            <IonTitle
              style={{
                color: "#062a40",
              }}
            >
              <h1>Gruppi che alleni</h1>
            </IonTitle>
            {data.map((group) => (
              <GroupCard key={group.id} data={group} />
              // <IonCard key={group.id} routerLink={`/admin/groups/${group.id}`}>
              //   <div key={group.id}>
              //     <h2>{group.description}</h2>
              //   </div>
              // </IonCard>
            ))}
          </div>
        )}
      </IonContent>
      <IonFooter className="ion-no-border ion-padding">
        <IonButton
          routerLink={`/admin/groups/add`}
          color="success"
          expand="block"
          size="large"
        >
          Aggiungi
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default GroupManager;

import React, { createContext, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { FirebaseContext } from "../FirebaseProvider/FirebaseProvider";
import { getToken, deleteToken } from "firebase/messaging";
import { AuthenticationContext } from "../AuthenticationProvider/AuthenticationProvider";
import useFirestoreDB from "../../hooks/basic/useFirestoreDB";
import { notificationsAreActive } from "../../utils/device";

async function requestNotificationPermission() {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log("Notification permission granted.");
      return true;
    } else {
      console.log("Unable to get permission to notify.");
    }
  } catch (error) {
    console.error("Error requesting notification permission", error);
  }
  return false;
}

async function getNotificationToken(firestoreMessaging) {
  try {
    const token = await getToken(firestoreMessaging, {
      vapidKey: import.meta.env.VITE_FIREBASE_APP_FIREBASE_FCM_KEY,
    });
    return token;
  } catch (error) {
    console.error("Error getting notification token", error);
  }
  return null;
}

// Create a new context for the notification provider
export const NotificationContext = createContext({});

// Create the notification provider component
const NotificationProvider = ({ children }) => {
  const { firestoreMessaging, firestoreDb } = useContext(FirebaseContext);
  const { isLogged } = useContext(AuthenticationContext);
  const { appUserId } = useContext(AuthenticationContext);
  const { createOrUpdateDocument } = useFirestoreDB(firestoreDb);

  const registerNotification = async () => {
    const result = await requestNotificationPermission();
    if (!result) {
      return false;
    }

    let token_name = window.localStorage.getItem("fcm_token_name");
    if (!token_name) {
      // create a random token name startiing with device_
      token_name = `device_${Math.random().toString(36).substr(2, 9)}`;
      window.localStorage.setItem("fcm_token_name", token_name);
    }

    const token = await getNotificationToken(firestoreMessaging);
    if (token && appUserId) {
      createOrUpdateDocument("users", appUserId, {
        [`fcm_token.${token_name}`]: token,
      });
    } else {
      console.error("Error getting notification token");
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (notificationsAreActive() && appUserId) {
      // refresh the notification token
      registerNotification();
      console.log("Notification token refreshed");
    }
  }, [appUserId]);

  useEffect(() => {
    if (isLogged === false) {
      // delete fcm subscription
      try {
        deleteToken(firestoreMessaging);
      } catch (error) {
        console.error("Error deleting notification token", error);
      }
    }
  }, [isLogged]);

  // Render the children only when the authentication state is loaded
  return (
    <NotificationContext.Provider
      value={{
        requestNotificationPermission,
        registerNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

NotificationProvider.displayName = "NotificationProvider";

NotificationProvider.propTypes = {
  children: PropTypes.node,
};

export default NotificationProvider;

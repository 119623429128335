import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileMedical } from "@fortawesome/free-solid-svg-icons";

function CertificateExpirationIcon({ onIconClick, expireDate }) {
  if (!expireDate) return null;

  const date = new Date(expireDate.seconds * 1000);
  const today = new Date();

  // icon color logic
  // if the expiration date is longer than the next 45 days, the icon will be green
  // if the expiration date is less than the next 45 days, the icon will be yellow
  // if the expiration date is less than the next 20 days, the icon will be orange
  // if the expiration date is less than the next 10 days, the icon will be red

  let color = "#5cb85c";
  const days = Math.floor((date - today) / (1000 * 60 * 60 * 24));
  if (days < 45 && days >= 20) color = "#ffd966";
  if (days < 20 && days >= 10) color = "#f0ad4e";
  if (days < 10) color = "#d9534f";

  return (
    <FontAwesomeIcon
      icon={faFileMedical}
      style={{
        color,
        fontSize: "1.5em",
      }}
      slot="end"
      onClick={onIconClick}
    ></FontAwesomeIcon>
  );
}

CertificateExpirationIcon.propTypes = {
  onIconClick: PropTypes.func,
  expireDate: PropTypes.string,
};

CertificateExpirationIcon.defaultProps = {
  onIconClick: () => { },
};

export default CertificateExpirationIcon;

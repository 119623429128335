import React, { createContext } from "react";
import PropTypes from "prop-types";
import { initializeApp } from "firebase/app";
import "firebase/auth";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getMessaging } from "firebase/messaging";

// Initialize Firebase
const firebaseApp = initializeApp({
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_APP_FIREBASE_MEASUREMENT_ID,
});

console.warn("Firebase PROJECT mode", import.meta.env.MODE);
const firebaseAuth = getAuth(firebaseApp);
const firestoreDb = getFirestore(firebaseApp);
const firestoreMessaging = getMessaging(firebaseApp);
const environment = import.meta.env.MODE;

// Create a Firebase context
export const FirebaseContext = createContext({
  firebaseApp,
  firebaseAuth,
  firestoreDb,
  firestoreMessaging,
  environment,
});

// Create a Firebase provider component
export const FirebaseProvider = ({ children }) => {
  return (
    <FirebaseContext.Provider
      value={{
        firebaseApp,
        firebaseAuth,
        firestoreDb,
        firestoreMessaging,
        environment,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseProvider;

FirebaseProvider.displayName = "FirebaseProvider";

FirebaseProvider.propTypes = {
  children: PropTypes.node,
};

import React from "react";
import PropTypes from "prop-types";
import { IonChip, IonIcon, IonLabel } from "@ionic/react";
import { closeCircle } from "ionicons/icons";

function ParticipantList({ participants, onRemoveClick }) {
  const onIconClick = (e, participant) => {
    onRemoveClick(e, participant);
  };
  return (
    <div className="participant-list">
      {participants.map((participant) => (
        <IonChip key={participant.id}>
          <IonLabel>{participant.fullName}</IonLabel>
          <IonIcon
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onIconClick(e, participant);
            }}
            icon={closeCircle}
          ></IonIcon>
        </IonChip>
      ))}
    </div>
  );
}

ParticipantList.propTypes = {
  participants: PropTypes.array,
  onRemoveClick: PropTypes.func,
};

export default ParticipantList;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

function TagModal({
  isOpen,
  tags,
  selectedTags,
  onDismiss,
  onConfirm,
  onModalDismiss,
}) {
  const [temporarySelectedTags, setTemporarySelectedTags] = useState([]);

  useEffect(() => {
    if (isOpen) {
      setTemporarySelectedTags(selectedTags);
    }
  }, [isOpen]);

  const onSelectionChange = (e, tag) => {
    const tagId = tag.id;
    if (e.detail.checked) {
      const selectedTag = tags.find((tag) => tag.id === tagId);
      setTemporarySelectedTags([...temporarySelectedTags, selectedTag]);
    } else {
      setTemporarySelectedTags(
        temporarySelectedTags.filter((tag) => tag.id !== tagId),
      );
    }
  };

  const onDismissModal = () => {
    onDismiss();
  };

  const onConfirmModal = () => {
    onConfirm(temporarySelectedTags);
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onModalDismiss}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onDismissModal}>Annulla</IonButton>
          </IonButtons>
          <IonTitle>Seleziona Tag</IonTitle>
          <IonButtons slot="end">
            <IonButton strong={true} onClick={onConfirmModal}>
              Conferma
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonList>
          {tags &&
            tags.map((tag) => (
              <IonItem key={tag.id}>
                <IonCheckbox
                  labelPlacement="end"
                  justify="start"
                  checked={temporarySelectedTags
                    .map((a) => a.id)
                    .includes(tag.id)}
                  onIonChange={(e) => onSelectionChange(e, tag)}
                >
                  {tag.description}
                </IonCheckbox>
              </IonItem>
            ))}
        </IonList>
      </IonContent>
    </IonModal>
  );
}

TagModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  tags: PropTypes.array.isRequired,
  selectedTags: PropTypes.array.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onModalDismiss: PropTypes.func.isRequired,
};

export default TagModal;

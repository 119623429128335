import React, { useContext, useEffect, useState } from "react";
import { Link, Route } from "react-router-dom";
import {
  IonApp,
  IonContent,
  IonHeader,
  IonMenu,
  IonMenuToggle,
  IonPage,
  IonRouterOutlet,
  IonTitle,
  IonToolbar,
  setupIonicReact,
  useIonToast,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

import "swiper/css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/global.scss";
import Login from "./pages/Login/Login";
import ForgotPassword from "./pages/Login/ForgotPassword";
import Register from "./pages/Login/Register";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import Profile from "./pages/Login/Profile";
import GroupManager from "./pages/Administration/GroupManager/GroupManager";
import GroupScheduler from "./pages/Administration/GroupScheduler/GroupScheduler";
import BookingLists from "./pages/Administration/BookingsList/BookingLists";
import AddOrUpdateGroup from "./pages/Administration/GroupManager/AddOrUpdateGroup";
import Detail from "./pages/Bookings/Detail";
import EditBooking from "./pages/Bookings/EditBooking";
import Add from "./pages/Bookings/Add";
import Home from "./pages/Home/Home";
import { AuthenticationContext } from "./providers/AuthenticationProvider/AuthenticationProvider";
import Header from "./components/Header/Header";
import Menu from "./components/Menu/Menu";
import Calendar from "./pages/Calendar/Calendar";
import CoachUsers from "./pages/Administration/CoachUsers/CoachUsers";
import Infos from "./pages/Infos/Infos";
import NotificationCheckModal from "./components/NotificationCheckModal/NotificationCheckModal";
import { close } from 'ionicons/icons';

setupIonicReact();

const App = () => {
  const { isLogged } = useContext(AuthenticationContext);


  return (
    <IonApp id="main-header" className={`${import.meta.env.MODE} TweenerApp`}>
      <IonReactRouter>
        <Menu />
        <IonPage id="main-content">
          {isLogged && <Header />}
          {isLogged && <NotificationCheckModal />}
          <IonContent>
            <IonRouterOutlet>
              <Route exact path="/login">
                <Login />
              </Route>
              <Route exact path="/forgot-password">
                <ForgotPassword />
              </Route>
              <Route exact path="/register">
                <Register />
              </Route>
              <ProtectedRoute exact path="/info">
                <Infos />
              </ProtectedRoute>
              <ProtectedRoute exact path="/profile">
                <Profile />
              </ProtectedRoute>
              <ProtectedRoute exact path="/coach-users">
                <CoachUsers />
              </ProtectedRoute>
              <ProtectedRoute path="/calendar">
                <Calendar />
              </ProtectedRoute>
              <ProtectedRoute exact path="/admin/groups">
                <GroupManager />
              </ProtectedRoute>
              <ProtectedRoute
                path="/group-scheduler/:groupId"
                component={GroupScheduler}
              />
              <ProtectedRoute path="/coach-bookings" component={BookingLists} />
              <ProtectedRoute
                path="/admin/groups/:groupId"
                component={AddOrUpdateGroup}
              />
              <ProtectedRoute
                path="/booking-details/:bookingId"
                component={Detail}
              />
              <ProtectedRoute
                path="/booking-edit/:bookingId"
                component={EditBooking}
              />
              <ProtectedRoute path="/booking/add" exact component={Add} />
              {/* <ProtectedRoute component={Home} /> */}
              <ProtectedRoute exact path="/home" component={Home} />
              <ProtectedRoute exact path="/" component={Home} />
              {/* <ProtectedRoute> */}
              {/* <AppSecuredContent /> */}
              {/* </ProtectedRoute> */}
            </IonRouterOutlet>
          </IonContent>
        </IonPage>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;

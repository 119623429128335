import { useContext } from "react";
import { collection, doc, addDoc, updateDoc } from "firebase/firestore";
import { FirebaseContext } from "../../providers/FirebaseProvider/FirebaseProvider";

function useSaveOrUpdateGroup() {
  const { firestoreDb } = useContext(FirebaseContext);
  const saveOrUpdateGroup = async (group) => {
    if (group.id) {
      const groupRef = doc(firestoreDb, "groups", group.id);
      await updateDoc(groupRef, group);
    } else {
      const groupRef = collection(firestoreDb, "groups");
      await addDoc(groupRef, group);
    }
  };

  return saveOrUpdateGroup;
}

export default useSaveOrUpdateGroup;

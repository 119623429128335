import React, { useContext, useRef, useState } from "react";
import {
  IonContent,
  IonPage,
  IonButton,
  IonIcon,
  IonFooter,
  IonText,
  useIonViewWillEnter,
  useIonViewDidLeave,
} from "@ionic/react";
import { logOutOutline } from "ionicons/icons";
import { AuthenticationContext } from "../../providers/AuthenticationProvider/AuthenticationProvider";
import {
  getNotificationLocalStorage,
  hasNotificationBlocked,
  hasNotificationFunctionality,
  hasNotificationPermission,
  isInstalled,
  isIOs,
} from "../../utils/device";
import NotificationButton from "../../components/NotificationButton/NotificationButton";
import { FormattedDate } from "react-intl";

const NotificationSection = () => {
  if ((isIOs() && !isInstalled()) || !hasNotificationFunctionality()) {
    return null;
  }
  const hasAccepted =
    getNotificationLocalStorage() === "true" && hasNotificationPermission();
  return (
    <div className="NotificationSection">
      <h3 style={{ fontWeight: "700" }}>Notifiche</h3>
      {hasAccepted && (
        <p>
          <IonText color="success">Le notifiche sono attive</IonText>
        </p>
      )}

      {hasNotificationBlocked() && (
        <p>
          <IonText color="danger">
            Le notifiche sono bloccate.
            <br />
            Riattivale nelle impostazioni del tuo dispositivo
          </IonText>
        </p>
      )}

      {!hasAccepted && !hasNotificationBlocked() && (
        <p className="NotificationMessage">
          <IonText color="danger">Le notifiche sono disattivate</IonText>
          <br />
          {hasNotificationFunctionality() && (
            <NotificationButton>Abilita</NotificationButton>
          )}
        </p>
      )}
    </div>
  );
};

const Profile = () => {
  const { logOutUser, user, userDetails } = useContext(AuthenticationContext);
  const [dateLastNotificationCheck, setDateLastNotificationCheck] = useState(
    new Date(),
  );
  const timerHanlder = useRef(null);
  useIonViewWillEnter(() => {
    // start a polling to check if the user has accepted the notifications
    timerHanlder.current = setInterval(() => {
      console.log("Checking notifications");
      setDateLastNotificationCheck(new Date());
    }, 1000);
    return () => clearInterval(timerHanlder.current);
  });

  useIonViewDidLeave(() => {
    if (timerHanlder.current) {
      clearInterval(timerHanlder.current);
    }
  });

  return (
    <IonPage>
      <IonContent className="ion-text-center ion-padding">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <img src="/tennis-player_3295395.png" alt="Avatar" width="150" />
          <h3
            style={{ fontWeight: "bold" }}
          >{`${userDetails?.nome} ${userDetails?.cognome}`}</h3>
          <h5>{user?.email}</h5>
        </div>
        {
          // if user has expired certificate, show a notification
          userDetails?.certificate_expire && (
            <div>
              <h3 style={{ fontWeight: "700" }}>Scadenza certificato medico</h3>
              <FormattedDate
                value={userDetails.certificate_expire.seconds * 1000}
                day="2-digit"
                month="long"
                year="numeric"
              />
            </div>
          )
        }
        <NotificationSection key={dateLastNotificationCheck.getTime()} />
      </IonContent>
      <IonFooter className="ion-no-border ion-padding">
        <IonButton
          expand="block"
          size="large"
          color="danger"
          onClick={() => logOutUser()}
        >
          <IonIcon slot="start" icon={logOutOutline} />
          Logout
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default Profile;

import { useContext, useEffect } from "react";
import useFirestore from "./basic/useFirestore";
import { collection, getDocs, query } from "firebase/firestore";
import { FirebaseContext } from "../providers/FirebaseProvider/FirebaseProvider";

function useAthletesByCoach(coachId) {
  const { firestoreDb } = useContext(FirebaseContext);

  const firestoreQuery = async () =>
    getDocs(query(collection(firestoreDb, "users", coachId, "athletes")));

  const parsingFuntion = (data) => {
    return data.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });
  };

  const { data, error, isLoading, refreshData } = useFirestore(
    firestoreQuery,
    parsingFuntion,
  );

  useEffect(() => {
    refreshData();
  }, [coachId]);

  return {
    data,
    error,
    isLoading,
    refreshData,
  };
}

export default useAthletesByCoach;

import { useContext, useEffect } from "react";
import { collection, getDocs, query } from "firebase/firestore";
import { FirebaseContext } from "../../providers/FirebaseProvider/FirebaseProvider";
import useFirestore from "../basic/useFirestore";


function useUsers(preventCall = false) {
  const { firestoreDb } = useContext(FirebaseContext);

  const firestoreQuery = async () => {
    return getDocs(query(collection(firestoreDb, "users")));
  };

  const parsingFuntion = (data) => {
    return data.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });
  };

  const { data, error, isLoading, refreshData } = useFirestore(
    firestoreQuery,
    parsingFuntion,
    preventCall,
  );

  useEffect(() => {
    refreshData();
  }, []);

  return {
    data,
    error,
    isLoading,
    refreshData,
  };
}

export default useUsers;

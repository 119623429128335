export function isIOs() {
  return /iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase());
}

export function isInstalled() {
  return window.navigator.standalone;
}

export function hasNotificationFunctionality() {
  return "Notification" in window;
}

export function hasNotificationPermission() {
  return Notification.permission === "granted";
}

export function hasNotificationBlocked() {
  const permission = Notification.permission === "denied";
  if (permission) {
    window.localStorage.removeItem("notification_accepted");
  }
  return permission;
}

export function setNotificationLocalStorage(accepted) {
  window.localStorage.setItem("notification_accepted", accepted);
}

export function getNotificationLocalStorage() {
  return window.localStorage.getItem("notification_accepted");
}

export function notificationsAreActive() {
  return (
    hasNotificationFunctionality() &&
    getNotificationLocalStorage() === "true" &&
    hasNotificationPermission()
  );
}

export default {
  isIOs,
  isInstalled,
  hasNotificationFunctionality,
  hasNotificationBlocked,
  hasNotificationPermission,
  setNotificationLocalStorage,
  getNotificationLocalStorage,
};

import { useContext, useEffect } from "react";
import useFirestore from "../basic/useFirestore";
import { collection, getDocs, query, where } from "firebase/firestore";
import { FirebaseContext } from "../../providers/FirebaseProvider/FirebaseProvider";

function useBookingsByClubAndFields(
  clubId,
  fieldId,
  fromDate,
  toDate,
  preventCall,
) {
  const { firestoreDb } = useContext(FirebaseContext);

  const firestoreQueryMethod = async () =>
    getDocs(
      query(
        collection(firestoreDb, "prenotazioni"),
        where("field", "==", `societa/${clubId}/campo/${fieldId}`),
        where("from", ">=", fromDate),
        where("to", "<=", toDate),
      ),
    );

  const parsingFuntion = (data) => {
    return data.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });
  };

  const { data, error, isLoading, refreshData } = useFirestore(
    firestoreQueryMethod,
    parsingFuntion,
    preventCall,
  );

  useEffect(() => {
    if (preventCall) return;
    refreshData();
  }, [clubId, fieldId, fromDate.getDate(), toDate.getDate()]);

  return {
    data,
    error,
    isLoading,
    refreshData,
  };
}

export default useBookingsByClubAndFields;

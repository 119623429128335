import { useContext } from "react";
import { FirebaseContext } from "../../providers/FirebaseProvider/FirebaseProvider";
import { doc, updateDoc } from "firebase/firestore";

function useUpdateUserData() {
  const { firestoreDb } = useContext(FirebaseContext);
  const updateAthlete = async (userId, { certificate_expire }) => {
    const userData = {
      certificate_expire,
    };

    const athleteRef = doc(firestoreDb, "users", userId);
    await updateDoc(athleteRef, userData);
  };

  return updateAthlete;
}

export default useUpdateUserData;

import { useContext, useEffect } from "react";
import useFirestore from "./basic/useFirestore";
import { collection, getDocs, query } from "firebase/firestore";
import { FirebaseContext } from "../providers/FirebaseProvider/FirebaseProvider";

function useFieldsByClub(clubId) {
  const { firestoreDb } = useContext(FirebaseContext);

  const firestoreQuery = () =>
    clubId
      ? getDocs(query(collection(firestoreDb, "societa", clubId, "campo")))
      : null;

  const parsingFuntion = (data) => {
    return data.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });
  };

  const { data, error, isLoading, refreshData } = useFirestore(
    firestoreQuery,
    parsingFuntion,
    !clubId,
  );

  useEffect(() => {
    if (clubId) {
      refreshData();
    }
  }, [clubId]);

  return {
    data,
    error,
    isLoading,
    refreshData,
  };
}

export default useFieldsByClub;

import React from "react";
import PropTypes from "prop-types";

function NoData({
  messageTitle = "Non hai eventi in programma",
  message = "Consulta gli eventi disponibili o contatta il tuo allenatore",
}) {
  return (
    <div
      className="ion-padding ion-margin"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100%",
        color: "#062a40",
        textAlign: "center",
      }}
    >
      <img src="/tennis-player_3295395.png" width="150" />
      <br />
      <h5
        style={{
          fontWeight: "bold",
        }}
      >
        {messageTitle}
      </h5>
      {message}
    </div>
  );
}

NoData.propTypes = {
  messageTitle: PropTypes.string,
  message: PropTypes.string,
};

export default NoData;

import {
  IonCard,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  useIonViewWillEnter,
} from "@ionic/react";
import React, { useContext, useState } from "react";
import { AuthenticationContext } from "../../../providers/AuthenticationProvider/AuthenticationProvider";
import useBookingsByCoach from "../../../hooks/bookings/useBookingsByCoach";
import LessonCard from "../../../components/LessonCard/LessonCard";
import { groupBookingsByDate } from "../../../utils/bookings";
import Spinner from "../../../components/Spinner/Spinner";
const BookingLists = () => {
  const fromDateDefault = new Date();
  // create a variable fromDate that contains the first day of the current month
  fromDateDefault.setDate(1);
  // create a variable toDate that contains the first day of the next month
  const toDateDefault = new Date(fromDateDefault);
  toDateDefault.setMonth(toDateDefault.getMonth() + 1);

  const { appUserId } = useContext(AuthenticationContext);
  const [fromDate, setFromDate] = useState(fromDateDefault);
  const [toDate, setToDate] = useState(toDateDefault);
  const { data, error, isLoading, refreshData } = useBookingsByCoach(
    appUserId,
    fromDate,
    toDate,
  );

  useIonViewWillEnter(() => {
    refreshData();
  });

  const handleRefresh = (e) => {
    console.log("Refreshing data");
    refreshData();
    e.detail.complete();
  };

  const bookingsGroupedBySortedDate = groupBookingsByDate(data);

  return (
    <IonPage>
      <IonContent>
        {isLoading && <Spinner />}
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonItem>
          <IonLabel>Data Inizio</IonLabel>
          <IonDatetimeButton datetime="fromDate"></IonDatetimeButton>
          <IonModal keepContentsMounted={true}>
            <IonDatetime
              presentation="date"
              id="fromDate"
              value={fromDate.toISOString()}
              onIonChange={(e) => setFromDate(new Date(e.detail.value))}
            ></IonDatetime>
          </IonModal>
        </IonItem>
        <IonItem>
          <IonLabel>Data Fine</IonLabel>
          <IonDatetimeButton datetime="toDate"></IonDatetimeButton>
          <IonModal keepContentsMounted={true}>
            <IonDatetime
              presentation="date"
              id="toDate"
              value={toDate.toISOString()}
              onIonChange={(e) => setToDate(new Date(e.detail.value))}
            ></IonDatetime>
          </IonModal>
        </IonItem>
        {error && <p>Error: {error.message}</p>}
        {bookingsGroupedBySortedDate &&
          bookingsGroupedBySortedDate.map((group) => (
            <>
              <h2>{group.date.toDateString()}</h2>
              {group.bookings.map((booking) => (
                <LessonCard key={booking.id} data={booking} />
              ))}
            </>
          ))}
      </IonContent>
    </IonPage>
  );
};

export default BookingLists;

import React, { useContext } from "react";
import PropTypes from "prop-types";
import { IonButton, useIonToast } from "@ionic/react";
import { NotificationContext } from "../../providers/NotificationsProvider/NotificationProvider";
import { setNotificationLocalStorage } from "../../utils/device";

function NotificationButton({ children }) {
  const { registerNotification } = useContext(NotificationContext);
  const [present] = useIonToast();

  const enableNotifications = async () => {
    const result = await registerNotification();
    if (result) {
      present({
        message: "Notifiche attivate",
        color: "success",
        duration: 3000,
      });
      setNotificationLocalStorage(true);
    } else {
      present({
        message: "Errore attivazione notifiche",
        color: "danger",
        duration: 3000,
      });
    }
  };

  return <IonButton color={"success"} onClick={enableNotifications}>{children}</IonButton>;
}

NotificationButton.propTypes = {
  children: PropTypes.node,
};

export default NotificationButton;

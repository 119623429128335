import React, { useState } from "react";
import {
  getAuth,
  onAuthStateChanged,
  sendEmailVerification,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useEffect } from "react";
import {
  IonButton,
  IonContent,
  IonInput,
  IonInputPasswordToggle,
  IonPage,
  IonText,
  useIonToast,
} from "@ionic/react";
import "./styles/Login.scss";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
const Login = () => {
  const [present] = useIonToast();
  const auth = getAuth();
  const history = useHistory();

  const [error, setError] = useState();
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        const uid = user.uid;
        // ...
      } else {
        // User is signed out
        // ...
      }
    });
    if (auth.currentUser && !auth.currentUser.emailVerified) {
      // User is signed in but not verified
      // Send verification email
      // ...
      // sendEmailVerification(auth.currentUser);
    }
  }, []);

  const login = (e) => {
    e.preventDefault();
    const auth = getAuth();
    const email = e.target[0].value;
    const password = e.target[1].value;

    // createUserWithEmailAndPassword(auth, email, password)
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        window.location.href = "/";
      })
      .catch((error, a, b) => {
        present({
          message: "Credenziali non valide!",
          duration: 3000,
          color: "danger",
        });
      });
  };

  return (
    <IonPage className="LoginPage">
      <IonContent className="LoginPageContent">
        <div className="LoginForm">
          <img
            className="LoginMainLogo"
            src="/logo.png"
            alt="logo Janus Tennis Academy"
          />
          <h1>Login</h1>
          <div>Accedi per continuare</div>
          <br />
          <div>
            Non hai un accont?{" "}
            <IonText color="primary">
              <Link to="/register" replace>
                Registrati!
              </Link>
            </IonText>
          </div>
          <form onSubmit={login}>
            <br />
            <IonInput
              label="Email"
              labelPlacement="stacked"
              fill="outline"
              placeholder="Inserisci la tua email"
              required
            ></IonInput>
            <br />
            <IonInput
              label="Password"
              labelPlacement="stacked"
              fill="outline"
              placeholder="Inserisci la tua password"
              type="password"
              required
            >
              <IonInputPasswordToggle slot="end"></IonInputPasswordToggle>
            </IonInput>
            <br />
            <IonButton expand="block" type="submit" size="large">
              Accedi
            </IonButton>
            <br />
            {error && <IonText color="danger">{error.message}</IonText>}
            <IonText color="primary">
              <Link to="/forgot-password" replace>
                Password dimenticata?
              </Link>
            </IonText>
          </form>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;

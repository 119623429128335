import React, { useContext } from "react";
import {
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  useIonViewWillEnter,
} from "@ionic/react";
import useUserBookings from "../../hooks/useUserBookings";
import { AuthenticationContext } from "../../providers/AuthenticationProvider/AuthenticationProvider";
import useUserSubscriptablesBookings from "../../hooks/useUserSubscriptablesBookings";
import Spinner from "../../components/Spinner/Spinner";
import BookingCard from "../../components/BookingCard/BookingCard";
import { Link } from "react-router-dom";
import useBookingsByCoach from "../../hooks/bookings/useBookingsByCoach";
import NoData from "../../components/NoData/NoData";

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 2);

const Home = () => {
  const { userDetails, appUserId } = useContext(AuthenticationContext);

  const {
    data,
    error,
    isLoading: isBookingDataLoading,
    refreshData,
  } = useUserBookings(appUserId, today, undefined, 3);

  const {
    data: coachData,
    error: coachError,
    isLoading: isCoachDataLoading,
    refreshData: refreshCoachData,
  } = useBookingsByCoach(appUserId, today, tomorrow, false);

  const {
    data: subscriptableData,
    isLoading: isSubscriptableDataLoading,
    refreshData: refreshSubscriptableData,
    error: subscriptableError,
  } = useUserSubscriptablesBookings(
    appUserId,
    new Date(),
    undefined,
    userDetails?.tags || [],
    !userDetails,
  );

  const isLoading =
    isBookingDataLoading || isSubscriptableDataLoading || isCoachDataLoading;

  useIonViewWillEnter(() => {
    refreshData();
    refreshSubscriptableData();
    refreshCoachData();
  });

  const handleRefresh = (e) => {
    refreshData();
    refreshSubscriptableData();
    refreshCoachData();
    e.detail.complete();
  };

  return (
    <IonPage>
      <IonContent id="main-content">
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        {isLoading && <Spinner />}
        {error && <p>Error: {error.message}</p>}
        {coachData && coachData.length > 0 && (
          <div>
            <h1
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px",
                color: "#062a40",
                letterSpacing: "0.0125em",
              }}
            >
              Le tue lezioni
              <Link
                to="/calendar"
                fill="clear"
                style={{
                  marginLeft: "auto",
                  fontSize: "14px",
                  textTransform: "uppercase",
                }}
              >
                Calendario &gt;
              </Link>
            </h1>
            {coachData.map((booking) => (
              <BookingCard
                key={booking.id}
                data={booking}
                imageUrl="smash.png"
              />
            ))}
          </div>
        )}
        <div>
          <h1
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0 20px",
              color: "#062a40",
              letterSpacing: "0.0125em",
            }}
          >
            I tuoi eventi
            <Link
              to="/calendar"
              fill="clear"
              style={{
                marginLeft: "auto",
                fontSize: "14px",
                textTransform: "uppercase",
              }}
            >
              Calendario &gt;
            </Link>
          </h1>
          {data && data.length === 0 && (
            <div
              style={{
                margin: "0 3rem",
              }}
            >
              <NoData />
            </div>
          )}
          {data &&
            data.map((booking) => (
              <BookingCard
                key={booking.id}
                data={booking}
                imageUrl="smash.png"
              />
            ))}
        </div>

        {subscriptableData && subscriptableData.length > 0 && (
          <div>
            <h1
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 20px",
                color: "#062a40",
                letterSpacing: "0.0125em",
              }}
            >
              Eventi disponibili
            </h1>
            {subscriptableError && <p>Error: {subscriptableError.message}</p>}
            {subscriptableData.map((booking) => (
              <BookingCard
                key={booking.id}
                data={booking}
                imageUrl="smash.png"
              />
            ))}
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Home;

import { useContext } from "react";
import { doc, deleteDoc } from "firebase/firestore";
import { FirebaseContext } from "../../providers/FirebaseProvider/FirebaseProvider";

function useDeleteBooking() {
  const { firestoreDb } = useContext(FirebaseContext);
  const deleteBooking = async (bookingId) => {
    if (bookingId) {
      const groupRef = doc(firestoreDb, "prenotazioni", bookingId);
      await deleteDoc(groupRef);
    }
  };

  return deleteBooking;
}

export default useDeleteBooking;

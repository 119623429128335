import { useContext, useEffect } from "react";
import useFirestore from "../basic/useFirestore";
import { doc, getDoc } from "firebase/firestore";
import { FirebaseContext } from "../../providers/FirebaseProvider/FirebaseProvider";

function useBooking(bookingId, preventCall) {
  const { firestoreDb } = useContext(FirebaseContext);

  const firestoreQueryMethod = async () =>
    getDoc(doc(firestoreDb, "prenotazioni", bookingId));

  const parsingFuntion = (data) => {
    return {
      id: data.id,
      ...data.data(),
    };
  };

  const { data, error, isLoading, refreshData } = useFirestore(
    firestoreQueryMethod,
    parsingFuntion,
    preventCall,
  );

  useEffect(() => {
    if (!preventCall && bookingId) {
      refreshData();
    }
  }, [bookingId]);

  return {
    data,
    error,
    isLoading,
    refreshData,
  };
}

export default useBooking;

import React from "react";
import PropTypes from "prop-types";
import { IonContent, IonPage, IonTitle } from "@ionic/react";

function Infos() {
  return (
    <IonPage>
      <IonContent>
        <div>
          <h1
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0 20px",
            }}
          >
            Informazioni utili
          </h1>
          <div className="ion-text-center">
            <img src="logo.png" alt="logo" style={{ maxWidth: "150px" }} />
          </div>
          <div
            style={{
              margin: "0 20px",
            }}
          >
            <div>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/tweener-bb9d7.appspot.com/o/public%2Fregolamento-scuola-tennis-janus-tennis-academy.pdf?alt=media&token=61bf3515-e71e-4f86-950a-fb0007f72d12"
                target="_blank"
                rel="noreferrer"
              >
                Regolamento scuola tennis
              </a>
            </div>
            <div>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/tweener-bb9d7.appspot.com/o/public%2Fscheda-ammissione-socio-2024.pdf?alt=media&token=e73e6e6a-5b9f-4c63-9b89-fb90fbfcca7f"
                target="_blank"
                rel="noreferrer"
              >
                Scheda ammissione socio
              </a>
            </div>
            <div>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/tweener-bb9d7.appspot.com/o/public%2Fquote-corsi-janus-tennis-academy.pdf?alt=media&token=f418b165-8fff-4040-a429-d16f18312c0f"
                target="_blank"
                rel="noreferrer"
              >
                Quote corsi
              </a>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}

Infos.propTypes = {};

export default Infos;

import React, { useContext } from "react";
import { IonButton, IonContent, IonMenu, IonMenuToggle } from "@ionic/react";
import { Link } from "react-router-dom";
import { AuthenticationContext } from "../../providers/AuthenticationProvider/AuthenticationProvider";
import { FirebaseContext } from "../../providers/FirebaseProvider/FirebaseProvider";

function Menu() {
  const { user, userDetails, isCoach, isJanus } = useContext(
    AuthenticationContext,
  );
  const { environment } = useContext(FirebaseContext);
  return (
    <IonMenu contentId="main-content" className="full-width-menu">
      <IonContent className="ion-padding">
        <div>
          <div>
            <h3
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <img
                src="fireball_3295226.png"
                alt="logo"
                style={{ maxWidth: "50px" }}
              />
              {userDetails?.nome} {userDetails?.cognome}
            </h3>
            <p>{user?.email}</p>
            <IonMenuToggle>
              <IonButton routerLink="/profile">Profilo</IonButton>
            </IonMenuToggle>
          </div>
        </div>
        <div>
          <IonMenuToggle>
            <Link to="/">Home</Link>
          </IonMenuToggle>
        </div>
        <div>
          <IonMenuToggle>
            <Link to="/calendar">Calendario</Link>
          </IonMenuToggle>
        </div>
        {(isJanus || isCoach) && (
          <div>
            <IonMenuToggle>
              <Link to="/info">Informazioni utili</Link>
            </IonMenuToggle>
          </div>
        )}
        {isCoach && (
          <>
            <div>
              <IonMenuToggle>
                <Link to="/admin/groups">Gestione Gruppi</Link>
              </IonMenuToggle>
            </div>
            <div>
              <IonMenuToggle>
                <Link to="/booking/add">Crea lezione</Link>
              </IonMenuToggle>
            </div>
            <div>
              <IonMenuToggle>
                <Link to="/coach-users">Allievi</Link>
              </IonMenuToggle>
            </div>
          </>
        )}
        {environment === "development" && <div>{environment}</div>}
      </IonContent>
    </IonMenu>
  );
}

Menu.propTypes = {};

export default Menu;

export const groupBookingsByDate = (bookings) => {
  const sortedBookings = [...(bookings ?? [])].sort(
    (a, b) => a.from.seconds - b.from.seconds,
  );
  const idsDictionary = {};
  const result = [];
  let currentDataKey;
  (sortedBookings ?? []).forEach((booking) => {
    if (idsDictionary[booking.id]) {
      return;
    }
    const bookingDate = new Date(booking.from.seconds * 1000);
    const dataKey = bookingDate.toLocaleDateString();
    if (currentDataKey !== dataKey) {
      currentDataKey = dataKey;
      result.push({
        date: bookingDate,
        bookings: [],
      });
    }
    result[result.length - 1].bookings.push(booking);
    idsDictionary[booking.id] = true;
  });
  return result;
};

export default {
  groupBookingsByDate,
};

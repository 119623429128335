import { useContext } from "react";
import { deleteDoc, doc } from "firebase/firestore";
import { FirebaseContext } from "../../providers/FirebaseProvider/FirebaseProvider";

function useRemoveCoachAthletes() {
  const { firestoreDb } = useContext(FirebaseContext);
  const removeAthletes = async (coachId, userId) => {
    const athleteRef = doc(firestoreDb, "users", coachId, "athletes", userId);

    await deleteDoc(athleteRef);
  };

  return removeAthletes;
}

export default useRemoveCoachAthletes;

import React from "react";
import { IonMenuButton } from "@ionic/react";
import "./styles/Header.scss";
import { Link } from "react-router-dom";

function Header() {
  return (
    <header className="Header ion-padding">
      <Link to="/">
        <img alt="App Logo" src="/logo.png" />
      </Link>
      <IonMenuButton></IonMenuButton>
    </header>
  );
}

export default Header;

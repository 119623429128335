import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import { IonChip } from "@ionic/react";

const normalizeDate = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

function DaySelector({
  daysLength = 7,
  selectedDay = new Date(),
  onChangeDay = () => {},
}) {
  const [days, setDays] = useState([]);
  const [normalizedSelectedDay, setNormalizedSelectedDay] = useState();

  const onChangeCallback = (day) => {
    setNormalizedSelectedDay(day);
    onChangeDay(day);
  };

  useEffect(() => {
    // create an array of days from today to today + days
    const daysResult = [];
    for (let i = 0; i < daysLength; i++) {
      let date = new Date();
      // take only the date part
      date.setDate(date.getDate() + i);
      date = normalizeDate(date);

      daysResult.push(date);
    }

    // set the days in the state
    setDays(daysResult);
    setNormalizedSelectedDay(normalizeDate(selectedDay));
  }, [daysLength]);

  const today = normalizeDate(new Date());

  return (
    <Swiper
      spaceBetween={2}
      slidesPerView={"auto"}
      onSlideChange={() => console.log("slide change")}
      onSwiper={(swiper) => console.log(swiper)}
    >
      {days.map((day) => (
        <SwiperSlide key={day}>
          <IonChip
            color={
              day.getTime() === normalizedSelectedDay.getTime()
                ? "success"
                : "primary"
            }
            onClick={() => onChangeCallback(day)}
          >
            <div>
              {/* print today or date */}
              {day.getTime() === today.getTime()
                ? "Oggi"
                : day.toLocaleDateString("it-IT", {
                    weekday: "long",
                    day: "numeric",
                    month: "long",
                  })}
            </div>
          </IonChip>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

DaySelector.propTypes = {
  daysLength: PropTypes.number,
  selectedDay: PropTypes.string,
  onChangeDay: PropTypes.func,
};

export default DaySelector;

import React from "react";
import { createRoot } from "react-dom/client";
import { IntlProvider } from "react-intl";
import App from "./App";
import { FirebaseProvider } from "./providers/FirebaseProvider/FirebaseProvider";
import AuthenticationProvider from "./providers/AuthenticationProvider/AuthenticationProvider";
import NotificationProvider from "./providers/NotificationsProvider/NotificationProvider";

const container = document.getElementById("root");
const root = createRoot(container);
const locale = navigator.language;

root.render(
  <React.StrictMode>
    <FirebaseProvider>
      <AuthenticationProvider>
        <NotificationProvider>
          <IntlProvider locale={locale}>
            <App />
          </IntlProvider>
        </NotificationProvider>
      </AuthenticationProvider>
    </FirebaseProvider>
  </React.StrictMode>,
);

import React from "react";
import PropTypes from "prop-types";

import {
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonIcon,
  IonImg,
  IonRouterLink,
  IonText,
} from "@ionic/react";
import { pricetagOutline, mapOutline, peopleOutline } from "ionicons/icons";

import "./styles/GroupCard.scss";

function GroupCard({ data, imageUrl = "planning.png" }) {
  const { id, from, participants, fieldName, clubName, label, description } =
    data;
  return (
    <IonRouterLink
      routerLink={`/admin/groups/${id}`}
      style={{ textDecoration: "none" }}
    >
      <IonCard
        style={{ display: "flex", flexDirection: "row" }}
        className="GroupCard"
      >
        <IonImg
          src={imageUrl}
          style={{
            width: "55px",
            paddingLeft: "1rem",
            boxSizing: "content-box",
          }}
        />
        <IonCardContent style={{ flex: 1, paddingLeft: "1rem" }}>
          {/* <IonText>
            <h5>
              <IonIcon icon={calendarOutline}></IonIcon>
              {new Date(from.seconds * 1000).toLocaleString()}
            </h5>
          </IonText> */}
          <IonCardTitle>{description}</IonCardTitle>
          <IonText>
            <h5>
              <IonIcon icon={pricetagOutline}></IonIcon>
              {label || "-"}
            </h5>
          </IonText>
          <IonText>
            <h5>
              <IonIcon icon={mapOutline}></IonIcon>
              {clubName} - {fieldName}
            </h5>
          </IonText>
          <IonText>
            <h5>
              <IonIcon icon={peopleOutline}></IonIcon>
              {participants
                .map((participant) => participant.fullName)
                .join(", ")}
            </h5>
          </IonText>
        </IonCardContent>
      </IonCard>
    </IonRouterLink>
  );
}

GroupCard.propTypes = {
  href: PropTypes.string,
  imageUrl: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.string,
    from: PropTypes.shape({ seconds: PropTypes.number }),
    participants: PropTypes.array,
    fieldName: PropTypes.string,
    clubName: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default GroupCard;

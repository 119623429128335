import React, { useState, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  updateProfile,
} from "firebase/auth";
import {
  IonButton,
  IonContent,
  IonInput,
  IonInputPasswordToggle,
  IonPage,
  useIonToast,
} from "@ionic/react";
import { Link, useHistory } from "react-router-dom";
import useUserRegistration from "../../hooks/bookings/useUserRegistration";
import Spinner from "../../components/Spinner/Spinner";

const Register = () => {
  const history = useHistory();
  const auth = getAuth();
  const [present] = useIonToast();
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const createOrUpdateUserInfos = useUserRegistration();

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      // if (user && name && surname) {
      //   console.log("User is signed in");
      //   console.log("Nome e cognome salvati:", name, surname); // Verifica che i valori siano presenti
      //   await createOrUpdateUserInfos(user.uid, name, surname);
      //   history.push("/"); // Naviga alla home senza ricaricare
      //   window.location.reload(); // Ricarica l'intero sito
      // }
    });
  }, [auth, name, surname]); // Aggiorna il useEffect per reagire a eventuali cambiamenti di `name` e `surname`

  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleRegister = async (e) => {
    e.preventDefault();

    if (!name || !surname) {
      present({
        message: "Nome e cognome non possono essere vuoti",
        duration: 3000,
        color: "danger",
      });
      return;
    }

    if (!isValidEmail(email)) {
      present({
        message: "Email non valida",
        duration: 3000,
        color: "danger",
      });
      return;
    }

    if (password !== confirmPassword) {
      present({
        message: "Le password non corrispondono",
        duration: 3000,
        color: "danger",
      });
      return;
    }

    setIsLoading(true);
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;

        updateProfile(user, {
          displayName: `${name} ${surname}`,
        })
          .then(async () => {
            present({
              message: "Account creato con successo",
              duration: 3000,
              color: "success",
            });
            if (user && name && surname) {
              setIsLoading(true);
              console.log("User is signed in");
              console.log("Nome e cognome salvati:", name, surname); // Verifica che i valori siano presenti
              await createOrUpdateUserInfos(user.uid, name, surname);
              await sleep(1000);
              // history.push("/"); // Naviga alla home senza ricaricare
              // debugger
              // window.location.reload(); // Ricarica l'intero sito
              // // setIsLoading(false);
              window.location.href = "/";
            }
          })
          .catch((error) => {
            setIsLoading(false);
            present({
              message: `Errore aggiornamento profilo: ${error.message}`,
              duration: 3000,
              color: "danger",
            });
          });
      })
      .catch((error) => {
        setIsLoading(false);
        present({
          message: `${error.message}. Codice errore: ${error.code}`,
          duration: 3000,
          color: "danger",
        });
      });
  };

  return (
    <IonPage className="RegisterPage">
      <IonContent className="RegisterPageContent">
        {isLoading && <Spinner />}
        {!isLoading && (
          <div className="LoginForm">
            <img
              className="LoginMainLogo"
              src="/logo.png"
              alt="logo Janus Tennis Academy"
            />
            <h1>Crea un accounta</h1>
            <div>
              Ti sei già iscritto? Accedi da{" "}
              <Link to="/login" replace>
                qui!
              </Link>
            </div>
            <form onSubmit={handleRegister}>
              <br />
              <IonInput
                label="Nome"
                labelPlacement="stacked"
                fill="outline"
                placeholder="Inserisci il tuo nome"
                value={name}
                onIonInput={(e) => setName(e.detail.value)}
                required
              />
              <br />
              <IonInput
                label="Cognome"
                labelPlacement="stacked"
                fill="outline"
                placeholder="Inserisci il tuo cognome"
                value={surname}
                onIonInput={(e) => setSurname(e.detail.value)}
                required
              />
              <br />
              <IonInput
                label="Email"
                labelPlacement="stacked"
                fill="outline"
                placeholder="Inserisci la tua email"
                value={email}
                onIonInput={(e) => setEmail(e.detail.value)}
                required
                type="email"
              />
              <br />
              <IonInput
                label="Password"
                labelPlacement="stacked"
                fill="outline"
                placeholder="Inserisci la tua password"
                value={password}
                onIonInput={(e) => setPassword(e.detail.value)}
                type="password"
                required
              >
                <IonInputPasswordToggle slot="end"></IonInputPasswordToggle>
              </IonInput>
              <br />
              <IonInput
                label="Conferma Password"
                labelPlacement="stacked"
                fill="outline"
                placeholder="Conferma la tua password"
                value={confirmPassword}
                onIonInput={(e) => setConfirmPassword(e.detail.value)}
                type="password"
                required
              >
                <IonInputPasswordToggle slot="end"></IonInputPasswordToggle>
              </IonInput>
              <br />
              <IonButton expand="block" type="submit" size="large">
                Registrati
              </IonButton>
            </form>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Register;

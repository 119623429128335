import React from "react";
import PropTypes from "prop-types";
import { IonChip, IonIcon, IonLabel } from "@ionic/react";
import { closeCircle } from "ionicons/icons";

function TagList({ tags, onRemoveClick }) {
  const onIconClick = (e, tag) => {
    onRemoveClick(e, tag);
  };
  return (
    <div className="tag-list">
      {tags.map((tag) => (
        <IonChip key={tag.id}>
          <IonLabel>{tag.description}</IonLabel>
          <IonIcon
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onIconClick(e, tag);
            }}
            icon={closeCircle}
          ></IonIcon>
        </IonChip>
      ))}
    </div>
  );
}

TagList.propTypes = {
  tags: PropTypes.array,
  onRemoveClick: PropTypes.func,
};

export default TagList;

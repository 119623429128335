import { useContext, useEffect } from "react";
import useFirestore from "./basic/useFirestore";
import { collection, getDocs, query } from "firebase/firestore";
import { FirebaseContext } from "../providers/FirebaseProvider/FirebaseProvider";
import useClubs from "./useClubs";

function useClubsByCoach(coachId) {
  const { firestoreDb } = useContext(FirebaseContext);

  const firestoreQuery = async () =>
    getDocs(query(collection(firestoreDb, "users", coachId, "clubs")));

  const parsingFuntion = (data) => {
    return data.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });
  };

  const {
    data: coachClubs,
    error: errorCoachClubs,
    isLoading: isLoadingCoachClubs,
    refreshData,
  } = useFirestore(firestoreQuery, parsingFuntion);

  const preventCall = !coachClubs || coachClubs.length === 0;
  const ids = coachClubs?.map((club) => club.id);
  const { data, error, isLoading } = useClubs(ids, preventCall);

  useEffect(() => {
    refreshData();
  }, [coachId]);

  return {
    data: data || [],
    error: error || errorCoachClubs,
    isLoading: isLoading || isLoadingCoachClubs,
    refreshData,
  };
}

export default useClubsByCoach;

import { useContext, useEffect } from "react";
import useFirestore from "./basic/useFirestore";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { FirebaseContext } from "../providers/FirebaseProvider/FirebaseProvider";

function useUserSubscriptablesBookings(
  userId,
  fromDate,
  toDate = new Date(2100, 1, 1),
  tags,
  preventCall,
) {
  const { firestoreDb } = useContext(FirebaseContext);
  const toDatePlusOneDay = new Date(toDate);
  toDatePlusOneDay.setDate(toDatePlusOneDay.getDate() + 1);
  toDatePlusOneDay.setHours(0, 0, 0, 0);
  const firestoreQuery = () =>
    getDocs(
      query(
        collection(firestoreDb, "prenotazioni"),
        where("tags", "array-contains-any", tags),
        where("from", ">=", fromDate),
        where("from", "<=", toDatePlusOneDay),
        orderBy("from", "asc"),
      ),
    );

  const parsingFuntion = (data) => {
    const result = data.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });
    // filter bookings where the user is not already subscribed
    return result
      .filter((booking) => {
        return !booking.participantUserIds.includes(userId);
      })
      .filter((booking) => {
        return booking.remainingSlots > 0;
      });
  };

  const { data, error, isLoading, refreshData } = useFirestore(
    firestoreQuery,
    parsingFuntion,
    preventCall,
  );

  useEffect(() => {
    refreshData();
  }, [userId]);

  return {
    data,
    error,
    isLoading,
    refreshData,
  };
}

export default useUserSubscriptablesBookings;

import { useContext } from "react";
import { addDoc, collection, doc, getDoc, setDoc } from "firebase/firestore";
import { FirebaseContext } from "../../providers/FirebaseProvider/FirebaseProvider";

function useAddCoachAthletes() {
  const { firestoreDb } = useContext(FirebaseContext);
  const addAthletes = async (coachId, users) => {
    for (const user of users) {
      const athleteRef = doc(
        firestoreDb,
        "users",
        coachId,
        "athletes",
        user.id,
      );
      const bookingDoc = await getDoc(athleteRef);

      if (!bookingDoc.exists()) {
        await setDoc(athleteRef, {
          id: user.id,
          userId: user.id,
          fullName: `${user.nome} ${user.cognome}`,
          nome: user.nome,
          cognome: user.cognome,
        });
      }
    }
  };

  return addAthletes;
}

export default useAddCoachAthletes;

import React from "react";
import PropTypes from "prop-types";

import {
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonIcon,
  IonImg,
  IonRouterLink,
  IonText,
} from "@ionic/react";
import { calendarOutline, mapOutline, peopleOutline } from "ionicons/icons";

import "./styles/LessonCard.scss";

function LessonCard({ data, imageUrl = "balls.png", className }) {
  const { id, from, participants, fieldName, clubName, label, description } =
    data;
  return (
    <IonRouterLink
      routerLink={`/booking-edit/${id}`}
      style={{ textDecoration: "none" }}
    >
      <IonCard
        style={{ display: "flex", flexDirection: "row" }}
        className={className ? `LessonCard ${className}` : "LessonCard"}
      >
        <IonImg
          src={imageUrl}
          style={{
            width: "55px",
            paddingLeft: "1rem",
            boxSizing: "content-box",
          }}
        />
        <IonCardContent style={{ flex: 1, paddingLeft: "1rem" }}>
          <IonText>
            <h5>
              <IonIcon icon={calendarOutline}></IonIcon>
              {new Date(from.seconds * 1000).toLocaleString()}
            </h5>
          </IonText>
          <IonCardTitle>{label || description || "Prenotazione"}</IonCardTitle>
          <IonText>
            <h5>
              <IonIcon icon={mapOutline}></IonIcon>
              {clubName} - {fieldName}
            </h5>
          </IonText>
          <IonText>
            <h5>
              <IonIcon icon={peopleOutline}></IonIcon>
              {participants
                .map((participant) => participant.fullName)
                .join(", ")}
            </h5>
          </IonText>
        </IonCardContent>
      </IonCard>
    </IonRouterLink>
  );
}

LessonCard.propTypes = {
  href: PropTypes.string,
  imageUrl: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.string,
    from: PropTypes.shape({ seconds: PropTypes.number }),
    participants: PropTypes.array,
    fieldName: PropTypes.string,
    clubName: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default LessonCard;

import {
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  useIonViewWillEnter,
  useIonViewDidLeave,
} from "@ionic/react";
import React, { useContext, useState } from "react";
import { AuthenticationContext } from "../../providers/AuthenticationProvider/AuthenticationProvider";
import useBookingsByCoach from "../../hooks/bookings/useBookingsByCoach";
import { groupBookingsByDate } from "../../utils/bookings";
import Spinner from "../../components/Spinner/Spinner";
import useUserBookings from "../../hooks/useUserBookings";
import useUserSubscriptablesBookings from "../../hooks/useUserSubscriptablesBookings";
import BookingCard from "../../components/BookingCard/BookingCard";
import NoData from "../../components/NoData/NoData";
import { FormattedDate } from "react-intl";

const fromDateDefault = new Date();
// create a variable toDate that contains the first day of the next month
const toDateDefault = new Date(fromDateDefault);
toDateDefault.setMonth(toDateDefault.getMonth() + 1);

const Calendar = () => {
  const { appUserId } = useContext(AuthenticationContext);
  const [fromDate, setFromDate] = useState(fromDateDefault);
  const [toDate, setToDate] = useState(toDateDefault);
  const [showFromDateModal, setShowFromDateModal] = useState(false);
  const [showToDateModal, setShowToDateModal] = useState(false);

  const {
    data = [],
    error,
    isLoading: isCoachDataLoading,
    refreshData,
  } = useBookingsByCoach(appUserId, fromDate, toDate);

  const {
    data: bookingsData = [],
    error: bookingsError,
    isLoading: isBookingDataLoading,
    refreshData: refreshBookingData,
  } = useUserBookings(appUserId, fromDate, toDate, 1000);

  const initState = () => {
    setFromDate(fromDateDefault);
    setToDate(toDateDefault);
    refreshDataCallback();
  };

  useIonViewWillEnter(() => {
    initState();
  });

  useIonViewDidLeave(() => {
    initState();
  });

  const handleRefresh = (e) => {
    refreshDataCallback();
    e.detail.complete();
  };

  const refreshDataCallback = () => {
    refreshData();
    refreshBookingData();
    // refreshSubscriptableData();
  };

  console.log(data, bookingsData);
  const bookingsGroupedBySortedDate = groupBookingsByDate([
    ...(data || []),
    ...(bookingsData || []),
    // ...(subscriptableData || []),
  ]);

  const isLoading = isCoachDataLoading || isBookingDataLoading;
  return (
    <IonPage>
      <IonContent>
        {isLoading && <Spinner />}
        <div>
          <h1
            style={{
              color: "#062a40",
              padding: "0 20px",
            }}
          >
            Calendario
          </h1>
          <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
          <IonList>
            <IonItem>
              <IonLabel>Data Inizio</IonLabel>
              <IonDatetimeButton
                datetime="calendarFromDate"
                onClick={() => setShowFromDateModal(true)}
              ></IonDatetimeButton>
              <IonModal
                keepContentsMounted={true}
                onIonModalDidDismiss={() => setShowFromDateModal(false)}
                isOpen={showFromDateModal}
              >
                <IonDatetime
                  presentation="date"
                  id="calendarFromDate"
                  value={fromDate.toISOString()}
                  onIonChange={(e) => {
                    setShowFromDateModal(false);
                    setFromDate(new Date(e.detail.value));
                    refreshDataCallback();
                  }}
                ></IonDatetime>
              </IonModal>
            </IonItem>
            <IonItem>
              <IonLabel>Data Fine</IonLabel>
              <IonDatetimeButton
                datetime="calendarToDate"
                onClick={() => setShowToDateModal(true)}
              ></IonDatetimeButton>
              <IonModal
                keepContentsMounted={true}
                onIonModalDidDismiss={() => setShowToDateModal(false)}
                isOpen={showToDateModal}
              >
                <IonDatetime
                  id="calendarToDate"
                  presentation="date"
                  value={toDate.toISOString()}
                  onIonChange={(e) => {
                    setShowToDateModal(false);
                    setToDate(new Date(e.detail.value));
                    refreshDataCallback();
                  }}
                ></IonDatetime>
              </IonModal>
            </IonItem>
          </IonList>
          {error && <p>Error: {error.message}</p>}
          {bookingsGroupedBySortedDate &&
            bookingsGroupedBySortedDate.map((group) => (
              <>
                <h4
                  style={{
                    color: "#062a40",
                    fontWeight: "bold",
                    padding: "0 20px",
                  }}
                >
                  <FormattedDate
                    value={group.date}
                    day="numeric"
                    month="long"
                    weekday="long"
                  />
                </h4>
                {group.bookings.map((booking) => (
                  <BookingCard key={booking.id} data={booking} />
                ))}
              </>
            ))}
          {
            // if there are no bookings, show the NoData component
            !isLoading && bookingsGroupedBySortedDate.length === 0 && (
              <div
                style={{
                  marginTop: "3rem",
                }}
              >
                <NoData />
              </div>
            )
          }
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Calendar;

import { useContext, useEffect } from "react";
import useFirestore from "../basic/useFirestore";
import { collection, getDocs, query, where } from "firebase/firestore";
import { FirebaseContext } from "../../providers/FirebaseProvider/FirebaseProvider";

function useBookingsByCoach(coachId, fromDate, toDate, preventCall) {
  const { firestoreDb } = useContext(FirebaseContext);

  const nextToDay = new Date(toDate);
  nextToDay.setDate(nextToDay.getDate() + 1);

  const firestoreQueryMethod = async () =>
    getDocs(
      query(
        collection(firestoreDb, "prenotazioni"),
        where("relatedSupervisorId", "==", coachId),
        where("from", ">=", fromDate),
        where("to", "<=", nextToDay),
      ),
    );

  const parsingFuntion = (data) => {
    return data.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });
  };

  const { data, error, isLoading, refreshData } = useFirestore(
    firestoreQueryMethod,
    parsingFuntion,
    preventCall,
  );

  useEffect(() => {
    if (preventCall) return;
    refreshData();
  }, [coachId, fromDate.getDate(), toDate.getDate()]);

  return {
    data,
    error,
    isLoading,
    refreshData,
  };
}

export default useBookingsByCoach;

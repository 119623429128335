import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

function NewAthleteModal({ isOpen, onDismiss, onConfirm }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [showError, setShowError] = useState(false);

  const onDismissModal = () => {
    onDismiss();
  };

  const onConfirmModal = () => {
    if (!firstName || !lastName) {
      setShowError(true);
      return;
    }
    onConfirm({
      firstName,
      lastName,
    });
  };

  useEffect(() => {
    if (isOpen) {
      setFirstName("");
      setLastName("");
      setShowError(false);
    }
  }, [isOpen]);

  const onFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const onLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onDismissModal}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onDismissModal}>Annulla</IonButton>
          </IonButtons>
          <IonTitle>Crea nuovo allievo</IonTitle>
          <IonButtons slot="end">
            <IonButton strong={true} onClick={onConfirmModal}>
              Conferma
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem>
            <IonInput label="Nome" onIonInput={onFirstNameChange}></IonInput>
          </IonItem>
          <IonItem>
            <IonInput label="Cognome" onIonInput={onLastNameChange}></IonInput>
          </IonItem>
        </IonList>
        {showError && (
          <p
            className="ion-text-center"
            style={{
              color: "red",
            }}
          >
            Compila tutti i campi
          </p>
        )}
      </IonContent>
    </IonModal>
  );
}

NewAthleteModal.propTypes = {
  hideSelectedAthletes: PropTypes.bool,
  isOpen: PropTypes.bool,
  athletes: PropTypes.array,
  selectedAthletes: PropTypes.array,
  onDismiss: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default NewAthleteModal;

import React from "react";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import { IonChip } from "@ionic/react";

function ClubSelector({
  data = [],
  selectedClubId = {},
  onChangeClub = () => { },
}) {
  const onChangeCallback = (club) => {
    onChangeClub(club.id);
  };

  console.log(data, selectedClubId);
  return (
    <Swiper
      spaceBetween={2}
      slidesPerView={"auto"}
      onSlideChange={() => console.log("slide change")}
      onSwiper={(swiper) => console.log(swiper)}
    >
      {data.map((club) => (
        <SwiperSlide key={club.id}>
          <IonChip
            color={club.id === selectedClubId ? "success" : "primary"}
            onClick={() => onChangeCallback(club)}
          >
            <div>{club.nome}</div>
          </IonChip>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

ClubSelector.propTypes = {
  data: PropTypes.array,
  selectedClubId: PropTypes.shape({
    id: PropTypes.string,
    nome: PropTypes.string,
  }),
  onChangeClub: PropTypes.func,
};

export default ClubSelector;

import { useEffect, useState } from "react";

const useFirestore = (query, parsingFunction, preventCall) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const executeQuery = async (q) => {
    try {
      setIsLoading(true);
      const result = await query();
      const parsedResult = parsingFunction(result);
      setData(parsedResult);
    } catch (error) {
      setData(null);
      console.error("Error executing query", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const refreshData = () => {
    setIsLoading(true);
    setData(null);
    executeQuery(query);
  };

  useEffect(() => {
    if (preventCall) {
      setIsLoading(false);
      return;
    }
    executeQuery(query);
  }, []);

  return {
    data,
    isLoading,
    error,
    refreshData,
  };
};

export default useFirestore;

import React from "react";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import { IonChip } from "@ionic/react";

function FieldSelector({
  data = [],
  selectedFieldId = {},
  onChangeField = () => { },
}) {
  const onChangeCallback = (field) => {
    onChangeField(field.id);
  };

  console.log(data, selectedFieldId);
  return (
    <Swiper
      spaceBetween={2}
      slidesPerView={"auto"}
      onSlideChange={() => console.log("slide change")}
      onSwiper={(swiper) => console.log(swiper)}
    >
      {data?.map((field) => (
        <SwiperSlide key={field.id}>
          <IonChip
            color={field.id === selectedFieldId ? "success" : "primary"}
            onClick={() => onChangeCallback(field)}
          >
            <div>{field.nome}</div>
          </IonChip>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

FieldSelector.propTypes = {
  data: PropTypes.array,
  selectedFieldId: PropTypes.shape({
    id: PropTypes.string,
    nome: PropTypes.string,
  }),
  onChangeField: PropTypes.func,
};

export default FieldSelector;

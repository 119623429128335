import React from "react";
// import PulseLoader from "react-spinners/PulseLoader";

import "./style/Spinner.scss";

function Spinner({ loading }) {
  // const randomNumber1to3 = Math.floor(Math.random() * 3) + 1;
  return (
    <div className="SpinnerContainer">
      <div className="Spinner">
        <img className="heartbeat" src={`/spinner1.png`} alt="Spinner Image" />
      </div>
    </div>
  );
}

Spinner.propTypes = {};

export default Spinner;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

function EditCoachUserModal({ user, isOpen, onWillDismiss, onConfirmModal }) {
  const [toDate, setToDate] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleDateChange = (e) => {
    setToDate(new Date(e.detail.value));
    setShowModal(false); // Chiudi il modal dopo la selezione
  };

  useEffect(() => {
    if (user && user.certificate_expire) {
      setToDate(user.certificate_expire.seconds * 1000);
    }
  }, [user]);

  return (
    <IonModal isOpen={isOpen} onWillDismiss={(ev) => onWillDismiss(ev)}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onWillDismiss}>Annulla</IonButton>
          </IonButtons>
          <IonTitle>Modifica allievo</IonTitle>
          <IonButtons slot="end">
            <IonButton
              strong={true}
              onClick={() => {
                onConfirmModal({ ...user, certificate_expire: toDate });
                onWillDismiss();
              }}
            >
              Conferma
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <h2 className="ion-text-center">
          {user && `${user.cognome} ${user.nome}`}
        </h2>
        <IonList>
          <IonItem onClick={() => setShowModal(true)}>
            <IonLabel>Scadenza certificato</IonLabel>
            <div>
              {/* Mostra la data selezionata o un trattino se non selezionata */}
              {toDate ? new Date(toDate).toLocaleDateString() : "-"}
            </div>

            {/* Modale per la selezione della data */}
          </IonItem>
        </IonList>
        <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
          <IonDatetime
            className="EditUserDatePicker"
            presentation="date"
            value={toDate ? new Date(toDate).toISOString() : ""}
            onIonChange={handleDateChange}
          />
          <IonButton onClick={() => setShowModal(false)}>Chiudi</IonButton>
        </IonModal>
      </IonContent>
    </IonModal>
  );
}

EditCoachUserModal.propTypes = {
  user: PropTypes.object,
  isOpen: PropTypes.bool,
  onConfirmModal: PropTypes.func,
  onWillDismiss: PropTypes.func,
};

export default EditCoachUserModal;

import React from "react";
import { Redirect, Route } from "react-router-dom";
import { AuthenticationContext } from "../../providers/AuthenticationProvider/AuthenticationProvider";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { user } = React.useContext(AuthenticationContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        user ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

export default ProtectedRoute;

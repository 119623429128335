import { useContext } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { FirebaseContext } from "../../providers/FirebaseProvider/FirebaseProvider";

function useDeleteBookingParticipation() {
  const { firestoreDb } = useContext(FirebaseContext);
  const deleteBookingParticipation = async (bookingId, userId) => {
    debugger;
    if (bookingId) {
      const bookingRef = doc(firestoreDb, "prenotazioni", bookingId);
      const bookingDoc = await getDoc(bookingRef);

      if (bookingDoc.exists()) {
        const bookingData = bookingDoc.data();
        const newParticipants = bookingData.participants.filter(
          (participant) => participant.userId !== userId,
        );

        const newParticipantUserIds = bookingData.participantUserIds.filter(
          (participantId) => participantId !== userId,
        );

        const remainingSlots =
          bookingData.maxParticipants - newParticipants.length;
        await updateDoc(bookingRef, {
          participants: newParticipants,
          participantUserIds: newParticipantUserIds,
          lastUpdateUserId: userId,
          remainingSlots,
        });
      }
    }
  };

  return deleteBookingParticipation;
}

export default useDeleteBookingParticipation;

import { useContext } from "react";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { FirebaseContext } from "../../providers/FirebaseProvider/FirebaseProvider";

function useUserRegistration() {
  const { firestoreDb } = useContext(FirebaseContext);
  const createOrUpdateUserInfos = async (userId, name, surname) => {
    if (userId) {
      const userRef = doc(firestoreDb, "users", userId);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        await updateDoc(userDoc, {
          nome: name,
          cognome: surname,
        });
      } else {
        console.error("User not found");
        const userData = {
          nome: name,
          cognome: surname,
          user_id: userId,
        };
        const userTableRef = collection(firestoreDb, "users");
        const document = await addDoc(userTableRef, userData);
        const userSecureTableRef = doc(firestoreDb, "users_secure", userId);

        await setDoc(userSecureTableRef, {
          appUserId: document.id,
          fullName: `${name} ${surname}`,
        });
      }
    }
  };

  return createOrUpdateUserInfos;
}

export default useUserRegistration;

import {
  IonButton,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonFooter,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTitle,
  useIonToast,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthenticationContext } from "../../../providers/AuthenticationProvider/AuthenticationProvider";
import useClubsByCoach from "../../../hooks/useClubsByCoach";
import useFieldsByClub from "../../../hooks/useFieldsByClub";
import useAthletesByCoach from "../../../hooks/useAthletesByCoach";
import useSaveOrUpdateGroup from "../../../hooks/groups/useSaveOrUpdateGroup";
import useUserGroupsByIdAndManager from "../../../hooks/groups/useUserGroupsByIdAndManager";
import useTagsByCoach from "../../../hooks/useTagsByCoach";
import Spinner from "../../../components/Spinner/Spinner";
import { useHistory } from "react-router";
import TagModal from "../../../components/TagModal/TagModal";
import AthletesModal from "../../../components/AthletesModal/AthletesModal";
import TagList from "../../../components/TagList/TagList";
import ParticipantList from "../../../components/ParticipantList/ParticipantList";
import PageTitle from "../../../components/PageTitle/PageTitle";

const AddOrUpdateGroup = ({ match }) => {
  const parsedGroupId =
    match.params.groupId !== "add" ? match.params.groupId : undefined;
  const [groupId, setGroupId] = useState(parsedGroupId);
  const [description, setDescription] = useState("");
  const [label, setLabel] = useState("");
  const [timeStart, setTimeStart] = useState(new Date().toISOString());
  const [timeStop, setTimeStop] = useState(new Date().toISOString());
  const [fromDate, setFromDate] = useState(new Date().toISOString());
  const [toDate, setToDate] = useState(new Date().toISOString());
  const [clubId, setClubId] = useState(null);
  const [fieldId, setFieldId] = useState(null);
  const [dayOfWeek, setDayOfWeek] = useState(null); // [0, 1, 2, 3, 4, 5, 6
  const [selectedAthletes, setSelectedAthletes] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [maxParticipants, setMaxParticipants] = useState(4);
  const saveOrUpdateGroup = useSaveOrUpdateGroup();
  const [present] = useIonToast();
  const history = useHistory();
  const formRef = useRef(null);

  const [openTagModal, setOpenTagModal] = useState(false);
  const [openAthletesModal, setOpenAthletesModal] = useState(false);

  useIonViewWillLeave(() => {
    if (openTagModal || openAthletesModal) {
      setOpenTagModal(false);
      setOpenAthletesModal(false);
      return false;
    }
  });

  const isUpdatePage = parsedGroupId !== undefined;

  const submitForm = () => {
    const submitEvent = new Event("submit", {
      bubbles: true,
      cancelable: true,
    });
    formRef.current.dispatchEvent(submitEvent); // Invia il form programmaticamente senza ricaricare la pagina
  };

  const resetFields = () => {
    setGroupId(null);
    setDescription("");
    setLabel("");
    setTimeStart(new Date().toISOString());
    setTimeStop(new Date().toISOString());
    setFromDate(new Date().toISOString());
    setToDate(new Date().toISOString());
    setClubId(null);
    setFieldId(null);
    setDayOfWeek(null);
    setSelectedAthletes([]);
    setSelectedTags([]);
    setMaxParticipants(4);
  };

  useIonViewWillEnter(() => {
    resetFields();
  });

  const { appUserId } = useContext(AuthenticationContext);
  const { data, isLoading: isLoadingClubs } = useClubsByCoach(appUserId);
  const { data: fields, isLoading: isLoadingFields } = useFieldsByClub(clubId);
  const { data: athletes, isLoading: isLoadingAthletes } =
    useAthletesByCoach(appUserId);
  const { data: group, isLoading: isLoadingGroup } =
    useUserGroupsByIdAndManager(
      groupId,
      appUserId,
      !groupId || groupId === "add",
    );
  console.log("group", groupId);
  const { data: tags, isLoading: isLoadingTags } = useTagsByCoach(appUserId);

  const isLoading =
    isLoadingClubs ||
    isLoadingFields ||
    isLoadingAthletes ||
    isLoadingGroup ||
    isLoadingTags;
  useEffect(() => {
    // TODO: guardare https://ionicframework.com/docs/api/datetime#time-zones
    if (group && group.id) {
      const fromTimeAddedWithLocalTimezone = new Date(
        group.fromTime.seconds * 1000,
      );
      // convert fromTimeAddedWithLocalTimezone to same time to utc
      fromTimeAddedWithLocalTimezone.setHours(
        fromTimeAddedWithLocalTimezone.getHours() -
        fromTimeAddedWithLocalTimezone.getTimezoneOffset() / 60,
      );

      const toTimeAddedWithLocalTimezone = new Date(
        group.toTime.seconds * 1000,
      );
      // convert toTimeAddedWithLocalTimezone to same time to utc
      toTimeAddedWithLocalTimezone.setHours(
        toTimeAddedWithLocalTimezone.getHours() -
        toTimeAddedWithLocalTimezone.getTimezoneOffset() / 60,
      );
      console.log("aaaaa", group);
      setGroupId(group.id);
      setDescription(group.description);
      setLabel(group.label);
      setTimeStart(fromTimeAddedWithLocalTimezone.toISOString());
      setTimeStop(toTimeAddedWithLocalTimezone.toISOString());
      setFromDate(new Date(group.fromDate.seconds * 1000).toISOString());
      setToDate(new Date(group.toDate.seconds * 1000).toISOString());
      setClubId(group.club.split("/").pop());
      setFieldId(group.field.split("/").pop());
      setDayOfWeek(+group.dayOfWeek);
      setSelectedAthletes(group.participants);
      setSelectedTags(group.tags || []);
      setMaxParticipants(group.maxParticipants);
    }
  }, [group]);

  const onSubmit = async (e) => {
    debugger;
    e.preventDefault();
    const formData = new FormData(e.target);
    const description = formData.get("description");
    const dateTimeStart = new Date(timeStart);
    debugger;
    // dateTimeStart.setHours(
    //   dateTimeStart.getHours() - dateTimeStart.getTimezoneOffset() / 60,
    // );
    const dateTimeStop = new Date(timeStop);
    // dateTimeStop.setHours(
    //   dateTimeStop.getHours() - dateTimeStop.getTimezoneOffset() / 60,
    // );

    const selectedClubName = data.find((club) => club.id === clubId).nome;
    const selectedFieldName = fields.find((field) => field.id === fieldId).nome;

    try {
      await saveOrUpdateGroup({
        id: groupId ? groupId : null,
        club: `/societa/${clubId}`,
        field: `societa/${clubId}/campo/${fieldId}`,
        dayOfWeek: dayOfWeek.toString(),
        description,
        label,
        managerId: appUserId,
        fromTime: dateTimeStart,
        toTime: dateTimeStop,
        fromDate: new Date(fromDate),
        toDate: new Date(toDate),
        participants: selectedAthletes,
        maxParticipants: +maxParticipants,
        tags: selectedTags,
        clubName: selectedClubName,
        fieldName: selectedFieldName,
      });
      history.goBack();
      present({
        message: "Gruppo salvato con successo",
        duration: 3000,
        color: "primary",
        position: "bottom",
      });
    } catch (error) {
      console.error(error);
      present({
        message: "Errore nel salvataggio del gruppo",
        duration: 3000,
        color: "danger",
        position: "bottom",
      });
    }
  };
  return (
    <IonPage>
      {isLoading && <Spinner />}
      <IonContent>
        <IonTitle
          style={{
            color: "#062a40",
          }}
        >
          <PageTitle>
            {!isUpdatePage && "Crea nuovo gruppo"}
            {isUpdatePage && "Modifica gruppo"}
          </PageTitle>
        </IonTitle>
        {!isLoading && (
          <>
            <form className="TweenerApp_form" onSubmit={onSubmit} ref={formRef}>
              <IonList>
                <IonItem>
                  <IonInput
                    className="ion-text-right"
                    label="Descrizione"
                    name="description"
                    value={description}
                    placeholder="Descrizione"
                    onIonChange={(e) => setDescription(e.target.value)}
                  ></IonInput>
                </IonItem>
                <IonItem>
                  <IonInput
                    className="ion-text-right"
                    label="Etichetta"
                    name="label"
                    value={label}
                    onIonChange={(e) => setLabel(e.target.value)}
                  ></IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel className="custom-label">
                    Giorno della settimana
                  </IonLabel>
                  <IonSelect
                    aria-label="Fruit"
                    interface="action-sheet"
                    placeholder="Giorno"
                    label=""
                    onIonChange={(e) => {
                      setDayOfWeek(e.detail.value);
                    }}
                    value={dayOfWeek}
                  >
                    {data &&
                      [
                        "Domenica",
                        "Lunedì",
                        "Martedì",
                        "Mercoledì",
                        "Giovedì",
                        "Venerdì",
                        "Sabato",
                      ].map((day, id) => (
                        <IonSelectOption key={id} value={id}>
                          {day}
                        </IonSelectOption>
                      ))}
                  </IonSelect>
                </IonItem>
                <IonItem>
                  <IonLabel>Data Inizio</IonLabel>
                  <IonDatetimeButton datetime="fromDate"></IonDatetimeButton>
                  <IonModal keepContentsMounted={true}>
                    <IonDatetime
                      presentation="date"
                      id="fromDate"
                      value={fromDate}
                      onIonChange={(e) => setFromDate(e.detail.value)}
                    ></IonDatetime>
                  </IonModal>
                </IonItem>
                <IonItem>
                  <IonLabel>Data Fine</IonLabel>
                  <IonDatetimeButton datetime="toDate"></IonDatetimeButton>
                  <IonModal keepContentsMounted={true}>
                    <IonDatetime
                      presentation="date"
                      id="toDate"
                      value={toDate}
                      onIonChange={(e) => setToDate(e.detail.value)}
                    ></IonDatetime>
                  </IonModal>
                </IonItem>
                <IonItem>
                  <IonLabel>Ora inizio</IonLabel>
                  <IonDatetimeButton datetime="timeStart"></IonDatetimeButton>

                  <IonModal keepContentsMounted={true}>
                    <IonDatetime
                      presentation="time"
                      id="timeStart"
                      value={timeStart}
                      onIonChange={(e) => setTimeStart(e.detail.value)}
                    // timeZone="local"
                    ></IonDatetime>
                  </IonModal>
                </IonItem>

                <IonItem>
                  <IonLabel id="eee">Ora fine</IonLabel>
                  <IonDatetimeButton
                    aria-labelledby="eee"
                    datetime="timeEnd"
                  ></IonDatetimeButton>

                  <IonModal keepContentsMounted={true}>
                    <IonDatetime
                      // timeZone="local"
                      presentation="time"
                      id="timeEnd"
                      value={timeStop}
                      onIonChange={(e) => setTimeStop(e.detail.value)}
                    ></IonDatetime>
                  </IonModal>
                </IonItem>
                <IonItem>
                  <IonLabel className="custom-label">
                    Seleziona un club
                  </IonLabel>
                  <IonSelect
                    aria-label="Fruit"
                    interface="action-sheet"
                    placeholder="Seleziona un club"
                    label=""
                    onIonChange={(e) => {
                      setClubId(e.detail.value);
                      setFieldId(null);
                    }}
                    value={clubId}
                  >
                    {data &&
                      data.map((club) => (
                        <IonSelectOption key={club.id} value={club.id}>
                          {club.nome}
                        </IonSelectOption>
                      ))}
                  </IonSelect>
                </IonItem>
                <IonItem>
                  <IonLabel className="custom-label">
                    Seleziona un campo
                  </IonLabel>
                  <IonSelect
                    aria-label="Seleziona un campo"
                    label=""
                    interface="action-sheet"
                    placeholder="Seleziona un campo"
                    value={fieldId}
                    onIonChange={(e) => setFieldId(e.detail.value)}
                  >
                    {fields &&
                      fields.map((field) => (
                        <IonSelectOption key={field.id} value={field.id}>
                          {field.nome}
                        </IonSelectOption>
                      ))}
                  </IonSelect>
                </IonItem>
                <IonItem>
                  <IonLabel>Massimo partecipanti</IonLabel>
                  <IonInput
                    min={1}
                    type="number"
                    className="ion-text-right"
                    value={maxParticipants}
                    onIonChange={(e) => setMaxParticipants(e.target.value)}
                  ></IonInput>
                </IonItem>
                <IonItem onClick={() => setOpenTagModal(true)}>
                  <IonLabel className="custom-label">Tag</IonLabel>
                  <TagList
                    tags={selectedTags}
                    onRemoveClick={(e, currentTag) => {
                      e.preventDefault();
                      setSelectedTags(
                        selectedTags.filter((tag) => tag.id !== currentTag.id),
                      );
                    }}
                  />
                  {/* <IonButton onClick={() => setOpenTagModal(true)}>
                    <IonIcon icon={addCircleOutline}></IonIcon>
                  </IonButton> */}
                </IonItem>
                <IonItem onClick={() => setOpenAthletesModal(true)}>
                  <IonLabel className="custom-label">Allievi</IonLabel>
                  <ParticipantList
                    participants={selectedAthletes}
                    onRemoveClick={(e, currentParticipant) => {
                      e.preventDefault();
                      setSelectedAthletes(
                        selectedAthletes.filter(
                          (participant) =>
                            participant.userId !== currentParticipant.userId,
                        ),
                      );
                    }}
                  />
                </IonItem>
              </IonList>
            </form>
            <AthletesModal
              isOpen={openAthletesModal}
              athletes={athletes}
              selectedAthletes={selectedAthletes}
              onConfirm={(athletes) => {
                setSelectedAthletes(athletes);
                setOpenAthletesModal(false);
              }}
              onDismiss={() => setOpenAthletesModal(false)}
            />
            <TagModal
              isOpen={openTagModal}
              tags={tags}
              selectedTags={selectedTags}
              onConfirm={(tags) => {
                setSelectedTags(tags);
                setOpenTagModal(false);
              }}
              onDismiss={() => setOpenTagModal(false)}
              onModalDismiss={() => {
                setOpenTagModal(false);
              }}
            />
          </>
        )}
        {!isLoading && parsedGroupId && (
          <IonButton routerLink={`/group-scheduler/${parsedGroupId}`}>
            Pianifica
          </IonButton>
          // <IonButton onClick={(e) => doPlanification()}>Calendarizza</IonButton>
        )}
      </IonContent>
      {!isLoading && (
        <IonFooter className="ion-no-border ion-padding">
          <IonButton
            color="success"
            expand="block"
            size="large"
            onClick={submitForm}
          >
            Salva
          </IonButton>
        </IonFooter>
      )}
    </IonPage>
  );
};

export default AddOrUpdateGroup;

AddOrUpdateGroup.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      groupId: PropTypes.string,
    }),
  }),
};

import React, { Fragment, useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonFooter,
  IonHeader,
  IonImg,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
  useIonToast,
  useIonViewWillEnter,
  useIonViewDidLeave,
} from "@ionic/react";
import useBooking from "../../hooks/bookings/useBooking";
import useDeleteBooking from "../../hooks/bookings/useDeleteBooking";
import useDeleteBookingParticipation from "../../hooks/bookings/useDeleteBookingParticipation";
import useAddBookingParticipation from "../../hooks/bookings/useAddBookingParticipation";
import { AuthenticationContext } from "../../providers/AuthenticationProvider/AuthenticationProvider";
import Spinner from "../../components/Spinner/Spinner";
import { useHistory } from "react-router";
import PageTitle from "../../components/PageTitle/PageTitle";
import { FormattedDate, FormattedTime } from "react-intl";

const Detail = ({ match }) => {
  const history = useHistory();
  const [present] = useIonToast();
  const parsedBookingId =
    match.params.bookingId !== "add" ? match.params.bookingId : null;
  const { userDetails, appUserId } = useContext(AuthenticationContext);
  const { data, isLoading, error, refreshData } = useBooking(parsedBookingId);
  const deleteBooking = useDeleteBooking();
  const deleteBookingParticipantion = useDeleteBookingParticipation();
  const subscribeBookingParticipantion = useAddBookingParticipation();
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showSubscribeAlert, setShowSubscribeAlert] = useState(false);
  const [showUnsubscribeAlert, setShowUnsubscribeAlert] = useState(false);
  const [isInPage, setIsInPage] = useState(false);

  useIonViewWillEnter(() => {
    refreshData();
    setIsInPage(true);
  });

  useIonViewDidLeave(() => {
    setShowDeleteAlert(false);
    setShowSubscribeAlert(false);
    setShowUnsubscribeAlert(false);
    setIsInPage(false);
  });

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const {
    clubName,
    description,
    fieldName,
    from,
    label,
    participants,
    participantUserIds = [],
    to,
  } = data || {};

  const canCancelEvent =
    appUserId &&
    data &&
    data.relatedSupervisorId &&
    appUserId === data.relatedSupervisorId;
  const canSubscribeEvent =
    appUserId && data && !participantUserIds.includes(appUserId);
  const canUnsubscribeEvent =
    appUserId && data && participantUserIds.includes(appUserId);

  return (
    <IonPage>
      {isLoading && isInPage && <Spinner />}
      {error && <p>Error: {error.message}</p>}
      {!isLoading && !error && isInPage && data && (
        <>
          <PageTitle>Dettaglio evento</PageTitle>
          <IonContent className="ion-padding">
            <Fragment>
              <div>
                <IonImg
                  src="smash.png"
                  style={{
                    height: "85px",
                  }}
                />
                <IonText class="ion-text-center ion-padding-top ion-padding-vertical">
                  <h1>{label || description || "Prenotazione"}</h1>
                </IonText>
                <h4 className="ion-text-center">
                  <FormattedDate
                    value={from.seconds * 1000}
                    day="2-digit"
                    month="long"
                    weekday="short"
                    year="numeric"
                  />
                  ,&nbsp;
                  <FormattedTime value={from.seconds * 1000} />
                  &nbsp;-&nbsp;
                  <FormattedTime value={to.seconds * 1000} />
                </h4>
              </div>
              <div className="ion-text-center">
                <h5>
                  {clubName} - {fieldName}
                </h5>
              </div>
              <div className="ion-text-center">
                <h2>Partecipanti</h2>
                {participants.map(({ id, userId, fullName }) => (
                  <IonChip color="primary" key={userId || id}>
                    {fullName}
                  </IonChip>
                ))}
              </div>
            </Fragment>
          </IonContent>
          <IonFooter
            className="ion-no-border ion-padding"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: ".5rem",
            }}
          >
            {canCancelEvent && (
              <div>
                <IonButton
                  color="danger"
                  expand="block"
                  size="large"
                  onClick={() => setShowDeleteAlert(true)}
                >
                  Cancella Evento
                </IonButton>
                <IonAlert
                  header="Vuoi cancellare l'evento?"
                  isOpen={showDeleteAlert}
                  onDidDismiss={() => setShowDeleteAlert(false)}
                  buttons={[
                    {
                      text: "Annulla",
                      role: "cancel",
                      handler: async () => {
                        setShowDeleteAlert(false);
                      },
                    },
                    {
                      text: "Conferma",
                      role: "confirm",
                      handler: async () => {
                        await deleteBooking(parsedBookingId);
                        setShowDeleteAlert(false);
                        // go back to the previous page
                        history.push("/");
                      },
                    },
                  ]}
                ></IonAlert>
              </div>
            )}
            {canUnsubscribeEvent && (
              <div>
                <IonButton
                  color="danger"
                  expand="block"
                  size="large"
                  onClick={() => setShowUnsubscribeAlert(true)}
                >
                  Cancellati
                </IonButton>
                <IonAlert
                  header="Vuoi cancellarti dall'evento?"
                  isOpen={showUnsubscribeAlert}
                  buttons={[
                    {
                      text: "Annulla",
                      role: "cancel",
                      handler: async () => {
                        setShowUnsubscribeAlert(false);
                      },
                    },
                    {
                      text: "Conferma",
                      role: "confirm",
                      handler: async () => {
                        try {
                          await deleteBookingParticipantion(
                            parsedBookingId,
                            appUserId,
                          );
                          present({
                            message: "Ti sei cancellato dall'evento",
                            duration: 500,
                            color: "success",
                            position: "top",
                          });
                        } catch (error) {
                          console.error(
                            "Error deleting the participation",
                            error,
                          );
                          present({
                            message: "Errore nella cancellazione",
                            duration: 500,
                            color: "danger",
                            position: "top",
                          });
                        } finally {
                          setShowUnsubscribeAlert(false);
                          // go back to the previous page
                          history.push("/");
                        }
                      },
                    },
                  ]}
                  onDidDismiss={() => {
                    setShowUnsubscribeAlert(false);
                  }}
                ></IonAlert>
              </div>
            )}
            {canSubscribeEvent && (
              <div>
                <IonButton
                  color="success"
                  expand="block"
                  size="large"
                  onClick={() => setShowSubscribeAlert(true)}
                >
                  Iscriviti
                </IonButton>
                <IonAlert
                  header="Vuoi iscriverti all'evento?"
                  isOpen={showSubscribeAlert}
                  onDidDismiss={() => setShowSubscribeAlert(false)}
                  buttons={[
                    {
                      text: "Annulla",
                      role: "cancel",
                      handler: async () => {
                        setShowSubscribeAlert(false);
                      },
                    },
                    {
                      text: "Conferma",
                      role: "confirm",
                      handler: async () => {
                        try {
                          await subscribeBookingParticipantion(
                            parsedBookingId,
                            userDetails,
                          );
                          present({
                            message: "Iscrizione effettuata con successo!",
                            duration: 500,
                            color: "success",
                            position: "top",
                          });
                        } catch (error) {
                          console.error(
                            "Error subscribing to the event",
                            error,
                          );
                          present({
                            message: "Posto non disponibile",
                            duration: 500,
                            color: "danger",
                            position: "top",
                          });
                        } finally {
                          setShowSubscribeAlert(false);
                          // go back to the previous page
                          history.push("/");
                        }
                      },
                    },
                  ]}
                ></IonAlert>
              </div>
            )}
          </IonFooter>
        </>
      )}
    </IonPage>
  );
};

export default Detail;

Detail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      bookingId: PropTypes.string,
    }),
  }),
};

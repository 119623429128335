import React, { useContext } from "react";
import PropTypes from "prop-types";

import {
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonIcon,
  IonImg,
  IonRouterLink,
  IonText,
} from "@ionic/react";
import { calendarOutline, mapOutline, peopleOutline } from "ionicons/icons";

import "./styles/BookingCard.scss";
import { AuthenticationContext } from "../../providers/AuthenticationProvider/AuthenticationProvider";
import { FormattedDate, FormattedTime } from "react-intl";

const getCardSettings = (data, user) => {
  if (user.id === data.relatedSupervisorId) {
    return {
      urlLink: `/booking-edit/${data.id}`,
      imageUrl: "balls.png",
      label: data.label,
      description: data.description,
      fieldName: data.fieldName,
      clubName: data.clubName,
      participants: data.participants,
      from: data.from,
    };
  } else if (data.participantUserIds.includes(user.id)) {
    return {
      urlLink: `/booking-details/${data.id}`,
      imageUrl: "rackets.png",
      label: data.label,
      description: data.description,
      fieldName: data.fieldName,
      clubName: data.clubName,
      participants: data.participants,
      from: data.from,
    };
  }
  // data.tags contains minumum one of the user tags
  else if (data.tags.some((tag) => user.tags.includes(tag))) {
    return {
      urlLink: `/booking-details/${data.id}`,
      imageUrl: "smash.png",
      label: data.label,
      description: data.description,
      fieldName: data.fieldName,
      clubName: data.clubName,
      participants: data.participants,
      from: data.from,
    };
  }
};

function BookingCard({ data, className }) {
  const { userDetails } = useContext(AuthenticationContext);

  debugger
  const {
    clubName,
    description,
    fieldName,
    from,
    imageUrl,
    label,
    participants,
    urlLink,
  } = getCardSettings(data, userDetails);
  return (
    <IonRouterLink routerLink={urlLink} style={{ textDecoration: "none" }}>
      <IonCard
        style={{ display: "flex", flexDirection: "row" }}
        className={className ? `BookingCard ${className}` : "BookingCard"}
      >
        <IonImg
          src={imageUrl}
          style={{
            width: "55px",
            paddingLeft: "1rem",
            boxSizing: "content-box",
          }}
        />
        <IonCardContent style={{ flex: 1, paddingLeft: "1rem" }}>
          <IonText>
            <h5>
              <IonIcon icon={calendarOutline}></IonIcon>
              <FormattedDate value={from.seconds * 1000} day="2-digit" month="long" weekday="short" year="numeric" />
              &nbsp;-&nbsp;
              <FormattedTime value={from.seconds * 1000} />
            </h5>
          </IonText>
          <IonCardTitle>{label || description || "Prenotazione"}</IonCardTitle>
          <IonText>
            <h5>
              <IonIcon icon={mapOutline}></IonIcon>
              {clubName} - {fieldName}
            </h5>
          </IonText>
          <IonText>
            <h5>
              <IonIcon icon={peopleOutline}></IonIcon>
              {participants
                .map((participant) => participant.fullName)
                .join(", ")}
            </h5>
          </IonText>
        </IonCardContent>
      </IonCard>
    </IonRouterLink>
  );
}

BookingCard.propTypes = {
  href: PropTypes.string,
  imageUrl: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.string,
    from: PropTypes.shape({ seconds: PropTypes.number }),
    participants: PropTypes.array,
    fieldName: PropTypes.string,
    clubName: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default BookingCard;

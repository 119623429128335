import { doc, updateDoc } from "firebase/firestore";

const useFirestoreDB = (firestoreDB) => {
  const createOrUpdateDocument = async (collection, docId, data) => {
    // if the document exists, update it; otherwise, create it using firestore api
    try {
      const docRef = doc(firestoreDB, collection, docId);
      await updateDoc(docRef, data);
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  return {
    createOrUpdateDocument,
  };
};

export default useFirestoreDB;

import React from "react";
import PropTypes from "prop-types";
import {
  IonBackButton,
  IonButtons,
  IonHeader,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

function PageTitle({ children, defaultBackHref = "/" }) {
  return (
    <IonHeader className="no-border">
      <IonToolbar
        style={{
          color: "#062a40",
        }}
      >
        <IonButtons slot="start">
          <IonBackButton defaultHref={defaultBackHref} />
        </IonButtons>
        <IonTitle
          style={{
            fontSize: "1.625rem",
            fontWeight: "bold",
          }}
        >
          {children}
        </IonTitle>
      </IonToolbar>
    </IonHeader>
  );
}

PageTitle.propTypes = {
  children: PropTypes.node.isRequired,
  defaultBackHref: PropTypes.string,
};

export default PageTitle;

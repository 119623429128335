import { useContext } from "react";
import { collection, doc, addDoc, updateDoc } from "firebase/firestore";
import { FirebaseContext } from "../../providers/FirebaseProvider/FirebaseProvider";

function useSaveOrUpdateBooking() {
  const { firestoreDb } = useContext(FirebaseContext);
  const saveOrUpdateBooking = async (booking) => {
    if (booking.id) {
      const groupRef = doc(firestoreDb, "prenotazioni", booking.id);
      await updateDoc(groupRef, booking);
    } else {
      const groupRef = collection(firestoreDb, "prenotazioni");
      await addDoc(groupRef, booking);
    }
  };

  return saveOrUpdateBooking;
}

export default useSaveOrUpdateBooking;

import React, { useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import {
  IonButton,
  IonContent,
  IonInput,
  IonPage,
  IonToast,
  useIonToast,
} from "@ionic/react";
import { Link, useHistory } from "react-router-dom";

import "./styles/Login.scss";
const ForgotPassword = () => {
  const [disableResetButton, setDisableResetButton] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const history = useHistory();
  const [present] = useIonToast();


  const reset = (e) => {
    e.preventDefault();
    const auth = getAuth();
    const email = e.target[0].value;
    setDisableResetButton(true);

    // createUserWithEmailAndPassword(auth, email, password)
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setSuccess(true);
        setError(null);
        setDisableResetButton(false);
        present({
          message: "Email inviata conControlla la tua email",
          duration: 3000,
          color: "success",
        });
      })
      .catch((error) => {
        setDisableResetButton(false);
        setError(error.message);
        setSuccess(false);
        present({
          message: error.message,
          duration: 3000,
          color: "danger",
        });
      });
  };

  return (
    <IonPage className="ForgotPasswordPage">
      <IonContent className="ForgotPasswordPageContent">
        <div className="LoginForm">
          <img
            className="LoginMainLogo"
            src="/logo.png"
            alt="logo Janus Tennis Academy"
          />
          <h1>Recupera Password</h1>
          <div>
            Torna al{" "}
            <Link to="/login" replace>
              login
            </Link>
          </div>
          <form onSubmit={reset}>
            <br />
            <IonInput
              label="Email"
              labelPlacement="stacked"
              fill="outline"
              placeholder="Inserisci la tua email"
              required
            ></IonInput>
            <br />
            <IonButton
              expand="block"
              type="submit"
              size="large"
              disabled={disableResetButton || success}
            >
              Cambia password
            </IonButton>
          </form>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ForgotPassword;

import { useContext } from "react";
import { FirebaseContext } from "../../providers/FirebaseProvider/FirebaseProvider";
import { doc, updateDoc } from "firebase/firestore";

function useUpdateCoachAthlete() {
  const { firestoreDb } = useContext(FirebaseContext);
  const updateAthlete = async (coachId, user) => {
    const athleteRef = doc(firestoreDb, "users", coachId, "athletes", user.id);
    await updateDoc(athleteRef, user);
  };

  return updateAthlete;
}

export default useUpdateCoachAthlete;

import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import useBooking from "../../hooks/bookings/useBooking";
import useDeleteBooking from "../../hooks/bookings/useDeleteBooking";
import { AuthenticationContext } from "../../providers/AuthenticationProvider/AuthenticationProvider";
import useAthletesByCoach from "../../hooks/useAthletesByCoach";
import useClubsByCoach from "../../hooks/useClubsByCoach";
import useFieldsByClub from "../../hooks/useFieldsByClub";
import useTagsByCoach from "../../hooks/useTagsByCoach";
import useSaveOrUpdateBooking from "../../hooks/bookings/useSaveOrUpdateBooking";
import AthletesModal from "../../components/AthletesModal/AthletesModal";
import TagModal from "../../components/TagModal/TagModal";
import TagList from "../../components/TagList/TagList";
import ParticipantList from "../../components/ParticipantList/ParticipantList";
import PageTitle from "../../components/PageTitle/PageTitle";
import { FormattedDate, FormattedTime } from "react-intl";

const EditBooking = ({ match }) => {
  const parsedBookingId =
    match.params.bookingId !== "add" ? match.params.bookingId : null;
  const [clubId, setClubId] = useState(null);
  const [fieldId, setFieldId] = useState(null);
  const { appUserId } = useContext(AuthenticationContext);
  const { data, isLoading, error } = useBooking(parsedBookingId);
  const deleteBooking = useDeleteBooking();
  const [selectedAthletes, setSelectedAthletes] = useState([]);
  const saveOrUpdateBooking = useSaveOrUpdateBooking();
  const [selectedTags, setSelectedTags] = useState([]);
  const { data: athletes } = useAthletesByCoach(appUserId);
  const { data: clubsData } = useClubsByCoach(appUserId);
  const { data: tags } = useTagsByCoach(appUserId);
  const { data: fieldsData } = useFieldsByClub(clubId);
  const [openAthletesModal, setOpenAthletesModal] = useState(false);
  const [openTagModal, setOpenTagModal] = useState(false);

  const updateBooking = async () => {
    const club = clubsData.find((c) => c.id === clubId);
    const field = fieldsData.find((f) => f.id === fieldId);

    const booking = {
      id: data.id,
      club: `/societa/${clubId}`,
      field: `societa/${clubId}/campo/${fieldId}`,
      participantUserIds: selectedAthletes
        .filter((a) => a.userId)
        .map((a) => a.userId),
      participants: selectedAthletes,
      remainingSlots:
        data.maxParticipants - selectedAthletes.length > 0
          ? data.maxParticipants - selectedAthletes.length
          : 0,
      tags: tags.map((t) => t.id),
      clubName: club.nome,
      fieldName: field.nome,
      lastUpdateUserId: appUserId,
    };
    await saveOrUpdateBooking(booking);
  };

  useEffect(() => {
    if (data && tags) {
      const tagsArray =
        tags?.filter((tag) => data.tags?.includes(tag.id)) || [];
      setSelectedTags(tagsArray);
      setSelectedAthletes(data.participants);
      setClubId(data.club.split("/").pop());
      setFieldId(data.field.split("/").pop());
    }
  }, [data, tags]);

  if (isLoading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const { from, to } = data;
  console.log(data);
  const canCancelEvent = appUserId && appUserId === data.relatedSupervisorId;
  return (
    <IonPage>
      <IonContent className="ion-ion-padding">
        <PageTitle>Dettaglio evento</PageTitle>
        <IonText>
          <h2>Inizio: </h2>
        </IonText>
        <p>
          <FormattedDate
            value={from.seconds * 1000}
            day="2-digit"
            month="long"
            weekday="short"
            year="numeric"
          />
          &nbsp;-&nbsp;
          <FormattedTime value={from.seconds * 1000} />
        </p>
        <IonText>
          <h2>Fine: </h2>
        </IonText>
        <p>
          <FormattedDate
            value={to.seconds * 1000}
            day="2-digit"
            month="long"
            weekday="short"
            year="numeric"
          />
          &nbsp;-&nbsp;
          <FormattedTime value={to.seconds * 1000} />
        </p>
        <IonList>
          <IonItem>
            <IonSelect
              aria-label="Fruit"
              interface="action-sheet"
              placeholder="Seleziona un club"
              label="Seleziona un club"
              onIonChange={(e) => {
                setClubId(e.detail.value);
                setFieldId(null);
              }}
              value={clubId}
            >
              {clubsData &&
                clubsData.map((club) => (
                  <IonSelectOption key={club.id} value={club.id}>
                    {club.nome}
                  </IonSelectOption>
                ))}
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonSelect
              aria-label="Seleziona un campo"
              interface="action-sheet"
              placeholder="Seleziona un campo"
              label="Seleziona un campo"
              value={fieldId}
              onIonChange={(e) => setFieldId(e.detail.value)}
            >
              {fieldsData &&
                fieldsData.map((field) => (
                  <IonSelectOption key={field.id} value={field.id}>
                    {field.nome}
                  </IonSelectOption>
                ))}
            </IonSelect>
          </IonItem>
          <IonItem onClick={() => setOpenAthletesModal(true)}>
            <IonLabel className="custom-label">Allievi</IonLabel>
            <ParticipantList
              participants={selectedAthletes}
              onRemoveClick={(e, currentParticipant) => {
                e.preventDefault();
                setSelectedAthletes(
                  selectedAthletes.filter(
                    (participant) =>
                      participant.userId !== currentParticipant.userId,
                  ),
                );
              }}
            />
          </IonItem>
          <IonItem onClick={() => setOpenTagModal(true)}>
            <IonLabel className="custom-label">Tag</IonLabel>
            <TagList
              tags={selectedTags}
              onRemoveClick={(e, currentTag) => {
                e.preventDefault();
                setSelectedTags(
                  selectedTags.filter((tag) => tag.id !== currentTag.id),
                );
              }}
            />
          </IonItem>
          {/* <IonItem onClick={() => setOpenTagModal(true)}>
              <IonButton>Open</IonButton>
              {selectedTags.map(({ description }) => description).join(", ")}
            </IonItem> */}
        </IonList>
        {canCancelEvent && (
          <div>
            <IonButton id="present-alert" color="danger">
              Cancella Evento
            </IonButton>
            <IonAlert
              header="Vuoi cancellare la prenotazione?"
              trigger="present-alert"
              buttons={[
                {
                  text: "Annulla",
                  role: "cancel",
                  handler: async () => {
                    // await deleteBooking(parsedBookingId);
                    // // go back to the previous page
                    // window.history.back();
                  },
                },
                {
                  text: "Conferma",
                  role: "confirm",
                  handler: async () => {
                    await deleteBooking(parsedBookingId);
                    // go back to the previous page
                    window.history.back();
                  },
                },
              ]}
              onDidDismiss={({ detail }) =>
                console.log(`Dismissed with role: ${detail.role}`)
              }
            ></IonAlert>
          </div>
        )}
        <div>
          <IonButton id="update-alert" color="primary">
            Aggiorna
          </IonButton>
          <IonAlert
            header="Vuoi aggiornare i dati?"
            trigger="update-alert"
            buttons={[
              {
                text: "Annulla",
                role: "cancel",
                handler: async () => {
                  updateBooking();
                },
              },
              {
                text: "Conferma",
                role: "confirm",
                handler: async () => {
                  updateBooking();
                  // await deleteBooking(parsedBookingId);
                  // go back to the previous page
                  // window.history.back();
                },
              },
            ]}
          ></IonAlert>
        </div>
        <AthletesModal
          isOpen={openAthletesModal}
          athletes={athletes}
          selectedAthletes={selectedAthletes}
          onConfirm={(athletes) => {
            setSelectedAthletes(athletes);
            setOpenAthletesModal(false);
          }}
          onDismiss={() => setOpenAthletesModal(false)}
        />
        <TagModal
          isOpen={openTagModal}
          tags={tags}
          selectedTags={selectedTags}
          onConfirm={(tags) => {
            setSelectedTags(tags);
            setOpenTagModal(false);
          }}
          onDismiss={() => setOpenTagModal(false)}
        />
      </IonContent>
    </IonPage>
  );
};

export default EditBooking;

EditBooking.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      bookingId: PropTypes.string,
    }),
  }),
};

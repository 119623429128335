import React from "react";
import PropTypes from "prop-types";

import {
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonIcon,
  IonImg,
  IonText,
} from "@ionic/react";
import { calendarOutline, mapOutline, peopleOutline } from "ionicons/icons";

import "./styles/SchedulerCard.scss";

function SchedulerCard({ data, imageUrl = "rackets.png", className }) {
  const { from, participants, fieldName, clubName, label, description } = data;
  return (
    <IonCard
      style={{ display: "flex", flexDirection: "row" }}
      className={className ? `SchedulerCard ${className}` : "SchedulerCard"}
    >
      <IonImg
        src={imageUrl}
        style={{
          width: "55px",
          paddingLeft: "1rem",
          boxSizing: "content-box",
        }}
      />
      <IonCardContent style={{ flex: 1, paddingLeft: "1rem" }}>
        <IonText>
          <h5>
            <IonIcon icon={calendarOutline}></IonIcon>
            {new Date(from.seconds * 1000).toLocaleString()}
          </h5>
        </IonText>
        <IonCardTitle>{label || description || "Prenotazione"}</IonCardTitle>
        <IonText>
          <h5>
            <IonIcon icon={mapOutline}></IonIcon>
            {clubName} - {fieldName}
          </h5>
        </IonText>
        <IonText>
          <h5>
            <IonIcon icon={peopleOutline}></IonIcon>
            {participants.map((participant) => participant.fullName).join(", ")}
          </h5>
        </IonText>
        {data.overlapped && !data.relatedBooking && (
          <IonText>
            <h5 style={{ color: "red" }}>Campo non prenotabile</h5>
          </IonText>
        )}
        {data.relatedBooking && (
          <IonText>
            <h5 style={{ color: "green" }}>
              Evento già creato:{" "} {data.relatedBooking.label || data.relatedBooking.description}
            </h5>
          </IonText>
        )}
      </IonCardContent>
    </IonCard>
  );
}

SchedulerCard.propTypes = {
  href: PropTypes.string,
  imageUrl: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.string,
    from: PropTypes.shape({ seconds: PropTypes.number }),
    participants: PropTypes.array,
    fieldName: PropTypes.string,
    clubName: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.string,
    relatedBooking: PropTypes.shape({
      label: PropTypes.string,
      description: PropTypes.string,
    }),
    overlapped: PropTypes.bool,
  }),
};

export default SchedulerCard;

const convertDateToHHMM = (date) => {
  return date.toLocaleTimeString("it-IT", {
    hour: "2-digit",
    minute: "2-digit",
  });
};

export function getBookablePeriods(
  bookingPeriods,
  selectedDay,
  minumumBookingTime = 30,
) {
  // get day of week of selected day
  const dayOfWeek = selectedDay.getDay();

  const validBookingPeriods = bookingPeriods.filter(
    (period) => period.giorno === dayOfWeek,
  );

  const bookablePeriods = [];
  const bookedTimeSummary = {};

  validBookingPeriods.forEach((period) => {
    const start = new Date(period.da.seconds * 1000);
    const end = new Date(period.a.seconds * 1000);
    // remove seconds and milliseconds
    start.setSeconds(0, 0);
    end.setSeconds(0, 0);
    while (start < end) {
      const localHHMMStart24Hours = convertDateToHHMM(start);
      bookablePeriods.push(localHHMMStart24Hours);
      bookedTimeSummary[localHHMMStart24Hours] = false;
      start.setMinutes(start.getMinutes() + minumumBookingTime);
    }
  });
  return { bookablePeriods, bookedTimeSummary };
}

export function getBookedPeriods(
  bookings,
  bookedTimeSummary,
  minumumBookingTime = 30,
) {
  (bookings || []).filter((booking) => {
    const from = new Date(booking.from.seconds * 1000);
    const to = new Date(booking.to.seconds * 1000);
    while (from < to) {
      const localHHMMStart24Hours = convertDateToHHMM(from);
      bookedTimeSummary[localHHMMStart24Hours] = true;
      from.setMinutes(from.getMinutes() + minumumBookingTime);
    }
  });
  return bookedTimeSummary;
}

export default { getBookablePeriods, getBookedPeriods };
